import './index.css';
import TokenValues from 'components/core/token-values';
import { PeriodVestingModel } from 'models/portfolio/PeriodVestingModel';
import { bigNumberToStr, unixDateToStr } from 'utils/strings';

const VestingScheduleTable = ({ data }: { data: PeriodVestingModel[] }) => {
  return (
    <table className='w-full'>
      <thead>
        <tr className='table_vesting_header'>
          <th>Date</th>
          <th>Tokens</th>
          <th>%</th>
          <th>Total USD</th>
        </tr>
      </thead>

      <tbody className='table_vesting_body'>
        {data.map((vesting) => (
          <tr key={vesting.unfreezeTime}>
            <td>{unixDateToStr(vesting.unfreezeTime, 'DD.MM.YYYY')}</td>
            <td>
              <TokenValues tokens={[vesting.part]} />
            </td>
            <td>{bigNumberToStr(vesting.percent, 2)}%</td>
            <td className='font-bold'>
              ${bigNumberToStr(vesting.cumulativePart.usdValue, 0)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default VestingScheduleTable;
