import './index.css';
import BigNumber from 'bignumber.js';
import { bigNumberToStr } from 'utils/strings';

interface Token {
  amount: BigNumber;
  symbol: string;
  usdValue?: BigNumber;
}

const TokenValues = ({ tokens }: { tokens?: Token[] }) => {
  if (!tokens) {
    return null;
  }

  return (
    <div>
      {tokens.map((token: Token, index) => (
        <div
          key={index}
          className='relative token_values_item flex flex-wrap text-sm gap-x-1'
        >
          <span>
            {bigNumberToStr(token.amount, 3, { useShortNotation: true })}
          </span>
          <span>{token.symbol}</span>
          {token.usdValue && (
            <span className='text-highlight font-semibold'>
              (${bigNumberToStr(token.usdValue, 0)})
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

export default TokenValues;
