import { useCallback, useMemo, useState } from 'react';
import Button from 'components/core/button';
import SingleSelect, {
  SingleSelectValue,
} from 'components/core/select/single-select';

const EditExchangeKey = ({
  initId,
  exchangeKeyOptions,
  onConfirm,
  onCancel,
}: {
  initId?: string;
  exchangeKeyOptions: SingleSelectValue[];
  onConfirm: (id: string, oldId?: string) => void;
  onCancel: () => void;
}) => {
  const [selectedId, setSelectedId] = useState(initId);

  const onClick = useCallback(() => {
    if (!selectedId) {
      return;
    }

    onConfirm(selectedId, initId);
  }, [selectedId, onConfirm, initId]);

  const selectedExchangeKeyOption = useMemo(
    () => exchangeKeyOptions.find((x) => x.value === selectedId),
    [exchangeKeyOptions, selectedId]
  );

  return (
    <div className='flex flex-col'>
      <div className='text-base'>Add exchange key to bundle</div>

      <div className='flex flex-col gap-2 mt-5 mb-8'>
        <div className='text-secondary-1 text-sm'>
          Select the key that you want to add:
        </div>

        <SingleSelect
          placeholderText='Select key...'
          onChange={(val) => setSelectedId(val?.value)}
          options={exchangeKeyOptions}
          value={selectedExchangeKeyOption}
        />
      </div>

      <div className='flex justify-between flex-row-reverse'>
        <Button
          variant='primary'
          className='w-28'
          onClick={onClick}
          disabled={selectedId === initId}
        >
          <span>Save</span>
        </Button>

        <Button
          variant='secondary'
          className='w-28'
          type='button'
          onClick={onCancel}
        >
          <span>Cancel</span>
        </Button>
      </div>
    </div>
  );
};
export default EditExchangeKey;
