export const strToPageNumber = (value: string | undefined | null) => {
  if (!value) {
    return 1;
  }

  const num = Number(value);

  if (isNaN(num) || num <= 0) {
    return 1;
  }

  return num;
};

export const getMaxPage = (total: number, pageSize: number) => {
  if (pageSize === 0) {
    return 1;
  }

  const val = Math.ceil(total / pageSize);

  if (val <= 0) {
    return 1;
  }

  return val;
};

export const getSkip = (page: number, pageSize: number) => {
  let val = (page - 1) * pageSize;

  if (val < 0) {
    val = 0;
  }

  return val;
};
