import Tokens from 'components/core/tokens';
import TokenValues from 'components/core/token-values';
import { PoolModel } from 'models/portfolio/PoolModel';
import { SupplyTokenModel } from 'models/portfolio/SupplyTokenModel';
import { bigNumberToStr } from 'utils/strings';
import ProjectCardTitle from '../project/ProjectCardTitle';
import Card from 'components/core/card';

const LiquidityPoolCards = ({ poolInfo }: { poolInfo: PoolModel[] }) => {
  return (
    <div className='flex flex-col gap-3'>
      {poolInfo.map((item, index) => (
        <Card key={index}>
          <ProjectCardTitle title='Liquidity pool' />

          <div className='grid grid-cols-2 gap-2'>
            <Tokens tokens={item.supplyTokenList} />
            <div>
              <span className='text-secondary-1'>USD Value</span>
              <div className='text-sm font-bold'>
                ${bigNumberToStr(item.totalUsdValue, 0)}
              </div>
            </div>

            <div className='col-span-2'>
              <span className='text-secondary-1'>Balance</span>
              <TokenValues
                tokens={item.supplyTokenList.map((token: SupplyTokenModel) => ({
                  amount: token.amount,
                  symbol: token.symbol,
                  usdValue: token.usdValue,
                }))}
              />
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};
export default LiquidityPoolCards;
