import SnipaRoutes from 'routes';
import Button from 'components/core/button';
import Dropdown from 'components/core/dropdown';
import Table from 'components/core/table';
import { BundleAccountModel } from 'modules/bundle/models/BundleAccountModel';
import { useMemo } from 'react';
import { BsPencilFill, BsThreeDots, BsTrash } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { Column } from 'react-table';
import { cutString } from 'utils/strings';

const AccountsTable = ({
  data,
  onEdit,
  onRemove,
}: {
  data: BundleAccountModel[];
  onEdit: (address: string, memo?: string) => void;
  onRemove: (address: string) => void;
}) => {
  const columns = useMemo<
    Column<{ address: string; memo: string | null; actions: any }>[]
  >(
    () => [
      {
        Header: 'Address',
        accessor: 'address',
        Cell: ({ row }) => (
          <NavLink
            to={`/${SnipaRoutes.profile.path}/${row.original.address}`}
            className='underline'
          >
            {cutString(row.original.address, 10, 4)}
          </NavLink>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Description',
        accessor: 'memo',
        Cell: ({ row }) => (
          <div className='whitespace-normal'>{row.original.memo}</div>
        ),
        disableSortBy: true,
        className: 'hidden md:table-cell',
      },
      {
        Header: () => <></>,
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className='flex justify-center'>
            <Dropdown
              menuButton={
                <Button variant='info' className='!p-1'>
                  <BsThreeDots className='text-sm' />
                </Button>
              }
              actions={[
                {
                  text: 'Edit',
                  onClick: () =>
                    onEdit(
                      row.original.address,
                      row.original.memo ?? undefined
                    ),
                  icon: <BsPencilFill />,
                },
                {
                  text: 'Remove',
                  onClick: () => onRemove(row.original.address),
                  icon: <BsTrash />,
                  className: 'text-danger-1',
                },
              ]}
            />
          </div>
        ),
        disableSortBy: true,
        className: 'w-16',
      },
    ],
    [onEdit, onRemove]
  );

  return <Table data={data} columns={columns} />;
};

export default AccountsTable;
