import NotificationArea from '../components/layout/NotificationArea';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext } from 'react';
import { NotificationStore } from 'stores/NotificationStore';

const notificationContext = createContext<NotificationStore>(
  {} as NotificationStore
);

export const ProvideNotification = observer(({ children }: any) => {
  const state = useLocalObservable(() => new NotificationStore());

  return (
    <notificationContext.Provider value={state}>
      <NotificationArea />

      {children}
    </notificationContext.Provider>
  );
});

export const useNotification = () => {
  return useContext(notificationContext);
};
