import ExchangeKeysTable from 'modules/bundle/components/tables/ExchangeKeysTable';
import EditExchangeKey from 'modules/bundle/components/modals/EditExchangeKey';
import { observer } from 'mobx-react-lite';
import { useLayoutStore } from 'providers/LayoutStoreProvider';
import { useCallback, useMemo } from 'react';
import { useBundlePortfolioStore } from '../providers/BundlePortfolioStoreProvider';
import { useBundleStore } from '../providers/BundleStoreProvider';
import Confirmation from 'components/layout/Confirmation';
import Container from 'components/core/container';
import Button from 'components/core/button';
import Card from 'components/core/card';
import { useBundleExchangeKeyStores } from '../providers/BundleExchangeKeyStoresProvider';
import { useStaticData } from 'providers/StaticDataStoreProvider';

const BundleExchangeKeys = observer(() => {
  const staticData = useStaticData();
  const bundleStore = useBundleStore();
  const bundlePortfolioStore = useBundlePortfolioStore();
  const layoutStore = useLayoutStore();

  const { exchangeKeyListStore } = useBundleExchangeKeyStores();

  const exchangeKeys = exchangeKeyListStore.exchangeKeys;
  const pickedExchangeKeys = bundlePortfolioStore.bundle?.exchangeKeys;

  const exchangeKeyOptions = useMemo(
    () =>
      exchangeKeys
        ?.map((exchangeKey) => ({
          value: exchangeKey.id,
          label: exchangeKey.memo,
          icon: staticData.getNetworkLogo(exchangeKey.exchange, 'exchange'),
        }))
        .filter(
          (ek) => !pickedExchangeKeys?.find((pek) => pek.id === ek.value)
        ) ?? [],
    [staticData, exchangeKeys, pickedExchangeKeys]
  );

  const editExchangeKeyInBundle = useCallback(
    async (exchangeKeyId: string, oldExchangeKeyId?: string) => {
      if (!bundlePortfolioStore.bundle) {
        return;
      }

      if (!oldExchangeKeyId) {
        await bundleStore.addExchangeKeyToBundle(
          bundlePortfolioStore.bundle.id,
          exchangeKeyId
        );
      } else {
        await bundleStore.editExchangeKeyInBundle(
          bundlePortfolioStore.bundle.id,
          oldExchangeKeyId,
          exchangeKeyId
        );
      }

      bundlePortfolioStore.fetchPortfolio(bundlePortfolioStore.bundle);
      layoutStore.hideContentModal();
    },
    [bundlePortfolioStore, bundleStore, layoutStore]
  );

  const removeExchangeKeyFromBundle = useCallback(
    async (exchangeKeyId: string) => {
      if (!bundlePortfolioStore.bundle) {
        return;
      }

      await bundleStore.removeExchangeKeyFromBundle(
        bundlePortfolioStore.bundle?.id,
        exchangeKeyId
      );

      bundlePortfolioStore.fetchPortfolio(bundlePortfolioStore.bundle);
      layoutStore.hideContentModal();
    },
    [bundlePortfolioStore, bundleStore, layoutStore]
  );

  const onAddExchangeKeyClick = useCallback(() => {
    layoutStore.showContentModal(
      <EditExchangeKey
        exchangeKeyOptions={exchangeKeyOptions}
        onConfirm={editExchangeKeyInBundle}
        onCancel={() => layoutStore.hideContentModal()}
      />
    );
  }, [editExchangeKeyInBundle, layoutStore, exchangeKeyOptions]);

  const onRemoveExchangeKeyClick = useCallback(
    (exchangeKeyId: string) => {
      let exchangeKey =
        bundlePortfolioStore.getBundleExchangeKey(exchangeKeyId);

      if (!exchangeKey) {
        return;
      }

      layoutStore.showContentModal(
        <Confirmation
          header={exchangeKey.memo}
          text='Are you sure you want to remove this exchange key from bundle?'
          onConfirm={() => removeExchangeKeyFromBundle(exchangeKeyId)}
          onCancel={() => layoutStore.hideContentModal()}
        />
      );
    },
    [bundlePortfolioStore, layoutStore, removeExchangeKeyFromBundle]
  );

  if (!bundlePortfolioStore.bundle) {
    return null;
  }

  return (
    <Container className='py-6 lg:py-12'>
      {bundlePortfolioStore.bundle.exchangeKeys.length === 0 && (
        <Card className='flex flex-col items-center gap-6 py-8'>
          <span className='text-center text-secondary-1'>
            No keys have been added to this package yet
          </span>
          <Button variant='primary' onClick={onAddExchangeKeyClick}>
            <span>Add key</span>
          </Button>
        </Card>
      )}

      {bundlePortfolioStore.bundle.exchangeKeys.length > 0 && (
        <>
          <div className='flex justify-end mb-5'>
            <Button
              variant='secondary'
              className='!py-0 !text-base'
              onClick={onAddExchangeKeyClick}
            >
              Add key
            </Button>
          </div>

          <ExchangeKeysTable
            data={bundlePortfolioStore.bundle.exchangeKeys}
            onRemove={onRemoveExchangeKeyClick}
          />
        </>
      )}
    </Container>
  );
});

export default BundleExchangeKeys;
