import SelectExchangeStep from './steps/SelectExchangeStep';
import SpecifyMemoAndApiKeyStep from './steps/SpecifyMemoAndApiKeyStep';
import ExchangeKeyCard from '../ExchangeKeyCard';
import SpecifySecretsStep from './steps/SpecifySecretsStep';
import { useExchangeKeyStores } from '../../providers/ExchangeKeyStoresProvider';
import { observer } from 'mobx-react-lite';

const NewExchangeKeyCard = observer(() => {
  const { exchangeKeyStore } = useExchangeKeyStores();

  return (
    <ExchangeKeyCard>
      {exchangeKeyStore.step === 'init' && <SelectExchangeStep />}
      {exchangeKeyStore.step === 'apiKey' && <SpecifyMemoAndApiKeyStep />}
      {exchangeKeyStore.step === 'secrets' && <SpecifySecretsStep />}
    </ExchangeKeyCard>
  );
});

export default NewExchangeKeyCard;
