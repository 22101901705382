import SnipaRoutes from 'routes';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { ProvideBundlePortfolioStore } from '../providers/BundlePortfolioStoreProvider';
import BundleAccounts from './BundleAccounts';
import BundleExchangeKeys from './BundleExchangeKeys';
import BundleDetailsNavbar from './BundleDetailsNavbar';
import BundlePortfolio from './BundlePortfolio';
import { ProvideBundleExchangeKeyStores } from '../providers/BundleExchangeKeyStoresProvider';
import BundleTransactionHistory from './BundleTransactionHistory';
import { ProvideTransactionHistoryStore } from 'providers/transaction-history/TransactionHistoryStoreProvider';
import { showExchangeKeys } from '../../../utils/beta';

const Bundle = () => {
  const { bundleId } = useParams();

  if (!bundleId) {
    return null;
  }

  return (
    <ProvideBundleExchangeKeyStores>
      <ProvideTransactionHistoryStore>
        <ProvideBundlePortfolioStore bundleId={bundleId}>
          <BundleDetailsNavbar />

          <Routes>
            <Route index element={<BundlePortfolio />} />

            <Route
              path={SnipaRoutes.bundles.accounts.path}
              element={<BundleAccounts />}
            />

            <Route
              path={SnipaRoutes.bundles.history.path}
              element={<BundleTransactionHistory />}
            />

            {showExchangeKeys && (
              <Route
                path={SnipaRoutes.bundles.exchangeKeys.path}
                element={<BundleExchangeKeys />}
              />
            )}
            <Route path='*' element={<Navigate to='' replace />} />
          </Routes>
        </ProvideBundlePortfolioStore>
      </ProvideTransactionHistoryStore>
    </ProvideBundleExchangeKeyStores>
  );
};

export default Bundle;
