import './index.css';
import SnipaRoutes from 'routes';
import { ReactComponent as ProfileLogoDark } from 'assets/images/profile-logo-dark.svg';
import { useMemo, useState } from 'react';
import {
  components,
  ControlProps,
  GroupBase,
  StylesConfig,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import { OptionProps } from 'react-select';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { SearchFieldStore, SearchFieldValue } from './store/SearchFieldStore';
import { useStaticData } from 'providers/StaticDataStoreProvider';
import { useApi } from 'providers/ApiProvider';
import { NavLink } from 'react-router-dom';
import { cutString } from 'utils/strings';
import { BsSearch } from 'react-icons/bs';

const SearchFieldControl = (props: ControlProps<SearchFieldValue>) => {
  return (
    <components.Control {...props}>
      <div className='flex items-center justify-center'>
        <BsSearch className='inline text-secondary-1 ml-2 text-lg' />

        {props.children}
      </div>
    </components.Control>
  );
};

const SearchFieldOption = (props: OptionProps<SearchFieldValue>) => {
  const navLinkUrl = useMemo(() => {
    switch (props.data.type) {
      case 'Address':
        return `/${SnipaRoutes.profile.path}/${props.data.value}`;
      case 'Project':
        return `/${SnipaRoutes.projects.path}/${props.data.value}`;
    }
  }, [props.data.type, props.data.value]);

  const labelValue = useMemo(() => {
    switch (props.data.type) {
      case 'Address':
        return cutString(props.data.label, 8, 4);
      default:
        return props.data.label;
    }
  }, [props.data.label, props.data.type]);

  const logoComponent = useMemo(() => {
    switch (props.data.type) {
      case 'Address':
        return <ProfileLogoDark className='w-6 h-6 sm:w-5 sm:h-5' />;
      case 'Project':
        return props.data.logoUrl ? (
          <img
            className='w-6 h-6 sm:w-5 sm:h-5'
            src={props.data.logoUrl}
            alt=''
          />
        ) : null;
    }
  }, [props.data.logoUrl, props.data.type]);

  return (
    <NavLink to={navLinkUrl}>
      <components.Option {...props}>
        <div className='py-1 px-3 flex gap-2 justify-between items-center'>
          <div className='flex items-center gap-2'>
            {logoComponent}
            <span className='text-primary-1 text-lg sm:text-base opacity-80'>
              {labelValue}
            </span>
          </div>

          <span className='sm:text-sm text-secondary-1'>{props.data.type}</span>
        </div>
      </components.Option>
    </NavLink>
  );
};

const SearchField = observer(() => {
  const api = useApi();
  const staticData = useStaticData();
  const store = useLocalObservable(() => new SearchFieldStore(api, staticData));

  const searchValues = (
    inputValue: string,
    callback: (options: SearchFieldValue[]) => void
  ) => {
    store.search(inputValue, callback);
  };

  const styles: StylesConfig<
    SearchFieldValue,
    boolean,
    GroupBase<SearchFieldValue>
  > = useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        background: 'var(--color-search-field-mainBg)',
        color: 'var(--color-search-field-mainText)',
        border: '1px solid var(--color-search-field-mainBorder)',
        borderRadius: '15px',
        boxShadow: 'none',
        '&:hover': {},
      }),
      input: (provided) => ({
        ...provided,
        color: 'var(--color-search-field-inputText)',
        overflow: 'hidden',
      }),
      placeholder: (provided) => ({
        ...provided,
        color: 'var(--color-search-field-placeholderText)',
      }),
      indicatorsContainer: () => ({ display: 'none' }),
      menu: (provided) => ({
        ...provided,
        background: 'var(--color-search-field-mainBg)',
        color: 'var(--color-search-field-mainText)',
        border: '1px solid var(--color-search-field-mainBorder)',
        borderRadius: '5px',
        boxShadow: 'var(--shadow-primary)',
        zIndex: '100',
      }),
      menuList: (provided) => ({
        ...provided,
        '::-webkit-scrollbar': {
          width: 'var(--width-scrollThumb)',
        },
        '::-webkit-scrollbar-thumb': {
          background: 'var(--color-scrollThumb)',
        },
        scrollbarWidth: 'thin',
      }),
      option: () => ({}),
    }),
    []
  );

  const [showMenu, setShowMenu] = useState(false);

  return (
    <AsyncSelect
      className='w-full'
      placeholder='Search'
      noOptionsMessage={() => 'No results'}
      loadOptions={searchValues}
      components={{
        Option: SearchFieldOption,
        Control: SearchFieldControl,
      }}
      value={null}
      onInputChange={(val) => setShowMenu(!!val)}
      menuIsOpen={showMenu}
      styles={styles}
    />
  );
});

export default SearchField;
