import Tokens from 'components/core/tokens';
import TokenValues from 'components/core/token-values';
import { PoolModel } from 'models/portfolio/PoolModel';
import { bigNumberToStr } from 'utils/strings';
import ProjectCardTitle from '../project/ProjectCardTitle';
import Card from 'components/core/card';

const StakingCards = ({ poolInfo }: { poolInfo: PoolModel[] }) => {
  return (
    <div className='flex flex-col gap-3'>
      {poolInfo.map((item, index) => (
        <Card key={index}>
          <ProjectCardTitle title='Staking' />

          <div className='grid grid-cols-2 gap-2'>
            <Tokens tokens={item.supplyTokenList} />

            <div>
              <span className='text-secondary-1'>Address</span>
              <div className='truncate max-w-[200px]'>{item.address}</div>
            </div>

            <div>
              <span className='text-secondary-1'>Balance</span>
              <TokenValues
                tokens={item.supplyTokenList?.map((x) => ({
                  symbol: x.symbol,
                  amount: x.amount,
                  usdValue: x.usdValue,
                }))}
              />
            </div>

            {item.stakingRewardList && (
              <div>
                <span className='text-secondary'>Reward</span>
                <div className='text-nowrap'>
                  <TokenValues
                    tokens={item.stakingRewardList?.map((x) => ({
                      symbol: x.symbol,
                      amount: x.amount,
                      usdValue: x.usdValue,
                    }))}
                  />
                </div>
              </div>
            )}

            <div>
              <span className='text-secondary-1'>USD Value</span>
              <div className='text-sm font-bold'>
                <span>${bigNumberToStr(item.totalUsdValue, 0)}</span>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};
export default StakingCards;
