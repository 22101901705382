import { ProjectWithStatsDTO } from 'api';
import BigNumber from 'bignumber.js';

export class ProjectWithStatsModel {
  id: string;
  link: string;
  name: string;
  networkId: string;

  totalTvlUsdValue: BigNumber;
  totalUsers: number;

  constructor(dto: ProjectWithStatsDTO) {
    this.id = dto.id;
    this.link = dto.link;
    this.name = dto.name;
    this.networkId = dto.networkId;
    this.totalTvlUsdValue = new BigNumber(dto.totalTvlUsdValue);
    this.totalUsers = dto.totalUsers;
  }
}
