import { BsPlusCircleFill } from 'react-icons/bs';
import SingleSelect, {
  SingleSelectValue,
} from 'components/core/select/single-select';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useExchangeKeyStores } from '../../../providers/ExchangeKeyStoresProvider';
import { useStaticData } from 'providers/StaticDataStoreProvider';

const SelectExchangeStep = observer(() => {
  const staticData = useStaticData();
  const { exchangeStore, exchangeKeyStore } = useExchangeKeyStores();

  const exchanges = exchangeStore.exchanges;

  const exchangeOptions = useMemo(
    () =>
      exchanges.map((exchange) => ({
        value: exchange.id,
        label: exchange.name,
        icon: staticData.getNetworkLogo(exchange.id, 'exchange'),
      })),
    [exchanges, staticData]
  );

  const onChange = useCallback(
    (option?: SingleSelectValue | null) => {
      if (!option) {
        return;
      }

      const exchange = exchangeStore.getExchange(option.value);

      if (!exchange) {
        return;
      }

      exchangeKeyStore.selectExchange(exchange);
    },
    [exchangeStore, exchangeKeyStore]
  );

  return (
    <div className='flex flex-col h-full justify-between'>
      <div className='font-bold flex items-center gap-2'>
        <BsPlusCircleFill className='text-3xl text-success-1' />
        Add exchange
      </div>
      <div className='flex flex-col gap-3'>
        <p className='text-base text-center'>
          Do you have multiple accounts of the same exchange?
        </p>
        <p className='text-secondary-1 text-center text-sm'>
          Just add another exchange card.
        </p>
      </div>
      <SingleSelect
        placeholderText='Select exchange...'
        className='mb-10'
        onChange={onChange}
        options={exchangeOptions}
        isSearchable={false}
      />
    </div>
  );
});

export default SelectExchangeStep;
