import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';
import { useApi } from 'providers/ApiProvider';
import { ExchangeKeyListStore } from 'stores/exchange-key/ExchangeKeyListStore';
import { ExchangeStore } from 'stores/exchange-key/ExchangeStore';
import { ExchangeKeyStore } from '../stores/ExchangeKeyStore';

type ExchangeKeyStores = {
  exchangeStore: ExchangeStore;
  exchangeKeyListStore: ExchangeKeyListStore;
  exchangeKeyStore: ExchangeKeyStore;
};

const exchangeKeyStoresContext = createContext<ExchangeKeyStores>(
  {} as ExchangeKeyStores
);

export const ProvideExchangeKeyStores = observer(
  ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const api = useApi();
    const exchangeStore = useLocalObservable(() => new ExchangeStore(api));
    const exchangeKeyStore = useLocalObservable(
      () => new ExchangeKeyStore(api)
    );
    const exchangeKeyListStore = useLocalObservable(
      () => new ExchangeKeyListStore(api)
    );

    useEffect(() => {
      exchangeStore.init();
    }, [exchangeStore]);

    useEffect(() => {
      exchangeKeyListStore.fetchExchangeKeys();
    }, [exchangeKeyListStore]);

    if (!exchangeStore.initialized) {
      return null;
    }

    if (!exchangeKeyListStore.exchangeKeys) {
      return null;
    }

    return (
      <exchangeKeyStoresContext.Provider
        value={{ exchangeStore, exchangeKeyStore, exchangeKeyListStore }}
      >
        {children}
      </exchangeKeyStoresContext.Provider>
    );
  }
);

export const useExchangeKeyStores = () => {
  return useContext(exchangeKeyStoresContext);
};
