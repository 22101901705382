import Button from '../button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IoMdCopy } from 'react-icons/io';
import { useMemo } from 'react';
import { cutString } from 'utils/strings';
import { useNotification } from 'providers/NotificationProvider';
import { NotificationType } from 'stores/NotificationStore';
import { useLgMediaQuery } from 'utils/responsiveness';

type CopiableFieldProps = {
  value?: string;
  onCopyMessage?: string;
};

const CopiableField = (props: CopiableFieldProps) => {
  const notificationService = useNotification();
  const copyNotification = useMemo(
    () => ({
      text: props.onCopyMessage ?? '',
      type: NotificationType.Success,
    }),
    [props.onCopyMessage]
  );

  const isDesktop = useLgMediaQuery();

  return (
    <div className='flex items-center justify-start'>
      <div className='py-1 px-2 text-base font-semibold text-primary'>
        <span>
          {isDesktop ? props.value : cutString(props.value ?? '', 8, 6)}
        </span>
      </div>
      <CopyToClipboard
        text={props.value ?? ''}
        onCopy={() => {
          if (!props.onCopyMessage || props.onCopyMessage === '') {
            return;
          }

          notificationService.addNotification(copyNotification);
        }}
      >
        <Button
          className='flex justify-center items-center px-2'
          variant='info'
        >
          <IoMdCopy className='text-lg' />
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default CopiableField;
