import { observer } from 'mobx-react-lite';
import { useLayoutStore } from 'providers/LayoutStoreProvider';
import { useCallback } from 'react';
import { useExchangeKeyStores } from '../providers/ExchangeKeyStoresProvider';
import { TbDotsVertical } from 'react-icons/tb';
import { BsPencilFill, BsTrash } from 'react-icons/bs';
import Confirmation from 'components/layout/Confirmation';
import Dropdown from 'components/core/dropdown';
import EditExchangeKey from './modals/EditExchangeKey';

const ExchangeKeyActions = observer(({ id }: { id: string }) => {
  const { exchangeKeyListStore } = useExchangeKeyStores();
  const layoutStore = useLayoutStore();

  const exchangeKey = exchangeKeyListStore.getExchangeKey(id);

  if (!exchangeKey) {
    return null;
  }

  const onEditExchangeKeyClick = useCallback(() => {
    layoutStore.showContentModal(
      <EditExchangeKey
        initValue={exchangeKey}
        onCancel={() => layoutStore.hideContentModal()}
        onConfirm={(value) => {
          const editAndHide = async () => {
            await exchangeKeyListStore.editExchangeKey(value.id, value.memo);
            layoutStore.hideContentModal();
          };

          editAndHide();
        }}
      />
    );
  }, [exchangeKey, exchangeKeyListStore, layoutStore]);

  const onRemoveExchangeKeyClick = useCallback(() => {
    layoutStore.showContentModal(
      <Confirmation
        header={exchangeKey.memo}
        text='Are you sure you want to remove this exchange key?'
        onConfirm={() => {
          const deleteAndHide = async () => {
            if (!exchangeKey) {
              return;
            }

            await exchangeKeyListStore.deleteExchangeKey(exchangeKey.id);
            layoutStore.hideContentModal();
          };

          deleteAndHide();
        }}
        onCancel={() => layoutStore.hideContentModal()}
      />
    );
  }, [exchangeKey, exchangeKeyListStore, layoutStore]);

  return (
    <Dropdown
      menuButton={
        <button className='flex items-center'>
          <TbDotsVertical />
        </button>
      }
      actions={[
        {
          text: 'Edit',
          onClick: onEditExchangeKeyClick,
          icon: <BsPencilFill />,
        },
        {
          text: 'Remove',
          onClick: onRemoveExchangeKeyClick,
          icon: <BsTrash />,
          className: 'text-danger-1',
        },
      ]}
    />
  );
});

export default ExchangeKeyActions;
