import { useStaticData } from 'providers/StaticDataStoreProvider';

const BundleExchangeKeyName = ({
  exchange,
  name,
}: {
  exchange: string;
  name: string;
}) => {
  const staticData = useStaticData();

  return (
    <div className='flex items-center gap-2'>
      <img
        className='w-6 h-6'
        src={staticData.getNetworkLogo(exchange, 'exchange')}
        alt=''
      />

      <span className='font-bold'>{name}</span>
    </div>
  );
};

export default BundleExchangeKeyName;
