import { ExchangeKeyDTO } from 'api';

export class ExchangeKeyModel {
  id: string;
  memo: string;
  name: string;
  exchange: string;

  constructor(dto: ExchangeKeyDTO) {
    this.id = dto.id;
    this.memo = dto.memo;
    this.name = dto.name;
    this.exchange = dto.exchange;
  }
}
