import './index.css';
import React, { useMemo } from 'react';

type ButtonProps = {
  variant: 'primary' | 'secondary' | 'info';
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const btnClasses = useMemo(() => {
      switch (props.variant) {
        case 'primary':
          return 'btn_primary';

        case 'secondary':
          return 'btn_secondary';

        case 'info':
          return 'btn_info';

        default:
          return '';
      }
    }, [props.variant]);

    return (
      <button
        {...props}
        className={`px-5 py-2 text-sm rounded-[50px] disabled:opacity-60 ${btnClasses} ${props.className}`}
        ref={ref}
      />
    );
  }
);

export default Button;
