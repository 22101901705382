import { Api } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';
import { ExchangeKeyModel } from 'models/exchange-key/ExchangeKeyModel';

export class ExchangeKeyListStore {
  constructor(private api: Api<unknown>) {
    makeAutoObservable(this);
  }

  private _exchangeKeys?: ExchangeKeyModel[];

  get exchangeKeys() {
    return this._exchangeKeys;
  }

  async fetchExchangeKeys() {
    const result = await this.api.exchange.exchangeKeyList();

    runInAction(() => {
      this._exchangeKeys = result.data.map((x) => new ExchangeKeyModel(x));
    });
  }

  getExchangeKey(id: string) {
    return this._exchangeKeys?.find((key) => key.id === id);
  }

  async editExchangeKey(id: string, exchangeKeyMemo: string) {
    await this.api.exchange.editExchangeKey({ id: id, memo: exchangeKeyMemo });
    await this.fetchExchangeKeys();
  }

  async deleteExchangeKey(id: string) {
    await this.api.exchange.deleteExchangeKey({ id: id });
    await this.fetchExchangeKeys();
  }
}
