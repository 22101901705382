import { useCallback } from 'react';
import * as yup from 'yup';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/core/input-field';
import Button from 'components/core/button';
import { ExchangeKeyMemoValidationSchema } from '../../../models/ExchangeKeyMemoValidationSchema';
import Exchange from '../../Exchange';
import { MdClose } from 'react-icons/md';
import { useExchangeKeyStores } from '../../../providers/ExchangeKeyStoresProvider';

type FormFields = {
  memo?: string;
  apiKey: string;
};

const ExchangeKeyFormSchema = yup.object({
  memo: ExchangeKeyMemoValidationSchema,
  apiKey: yup
    .string()
    .max(64, 'Max 64 characters')
    .nullable()
    .required('Field is required'),
});

const SpecifyMemoAndApiKeyStep = () => {
  const { exchangeKeyStore } = useExchangeKeyStores();

  const onCancel = useCallback(
    () => exchangeKeyStore.clear(),
    [exchangeKeyStore]
  );

  const { handleSubmit, control } = useForm<FormFields>({
    resolver: yupResolver(ExchangeKeyFormSchema),
    mode: 'onChange',
    defaultValues: {
      apiKey: exchangeKeyStore.apiKey,
      memo: exchangeKeyStore.memo,
    },
  });

  const applyForm = useCallback<SubmitHandler<FormFields>>(
    (data) => exchangeKeyStore.addApiKeyAndMemo(data),
    [exchangeKeyStore]
  );

  const description = 'Enter the API key';

  if (!exchangeKeyStore.exchange) {
    return null;
  }

  return (
    <form
      className='flex flex-col h-full gap-2'
      onSubmit={handleSubmit(applyForm)}
    >
      <div className='flex items-center justify-between'>
        <Exchange exchange={exchangeKeyStore.exchange.id} />
        <MdClose
          className='text-2xl text-secondary-1 cursor-pointer'
          onClick={onCancel}
        />
      </div>
      <div className='text-base text-secondary-1 my-2'>{description}</div>
      <div className='flex flex-col gap-2'>
        <Controller
          control={control}
          name='apiKey'
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <InputField
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              placeholder='API key'
              error={error?.message}
            />
          )}
        ></Controller>
        <Controller
          control={control}
          name='memo'
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <InputField
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              placeholder='Memo (optional)'
              error={error?.message}
            />
          )}
        ></Controller>
      </div>
      <div className='flex flex-row justify-between text-xs'>
        <div>
          <a
            className='underline text-highlight'
            href={exchangeKeyStore.exchange.generateKeyUri}
            target='_blank'
            rel='noreferrer'
          >
            <span>Generate key here</span>
          </a>
        </div>
        {exchangeKeyStore.exchange.apiGuideUri && (
          <div>
            <a
              className='underline text-highlight'
              href={exchangeKeyStore.exchange.apiGuideUri}
              target='_blank'
              rel='noreferrer'
            >
              <span>{exchangeKeyStore.exchange.name} API guide</span>
            </a>
          </div>
        )}
      </div>
      <div className='flex justify-between mt-auto flex-row-reverse'>
        <Button variant='primary' className='w-28' type='submit'>
          <span>Next</span>
        </Button>
        <Button
          variant='secondary'
          className='w-28'
          type='button'
          onClick={onCancel}
        >
          <span>Cancel</span>
        </Button>
      </div>
    </form>
  );
};

export default SpecifyMemoAndApiKeyStep;
