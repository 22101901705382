import { useSmMediaQuery } from 'utils/responsiveness';
import NotFound from 'components/layout/NotFound';
import SearchField from 'components/search-field';

const MobilePage = () => {
  const isDesktop = useSmMediaQuery();

  if (isDesktop) {
    return <NotFound />;
  }

  return (
    <div className='flex flex-col gap-4 mb-6'>
      <div className='flex justify-center w-full bg-navbar-mainBg p-5'>
        <SearchField />
      </div>
    </div>
  );
};

export default MobilePage;
