import Card from 'components/core/card';
import UsdValueChart from 'components/core/charts/usd-value-chart';
import { observer } from 'mobx-react-lite';
import { ImSpinner8 } from 'react-icons/im';
import { bigNumberToStr } from 'utils/strings';
import { useProjectStore } from '../providers/ProjectStoreProvider';

const ProjectAnalytics = observer(() => {
  const projectStore = useProjectStore();

  if (!projectStore.project) {
    return null;
  }

  return (
    <div className='flex flex-col gap-4'>
      <Card className='flex flex-col'>
        <span>Total Value Locked</span>
        <span className='text-xl font-bold'>
          {bigNumberToStr(projectStore.project.totalTvlUsdValue, 0)}$
        </span>
      </Card>

      <Card>
        {!projectStore.historyData && (
          <div className='flex items-center justify-center min-h-[250px]'>
            <ImSpinner8 className='text-3xl icon-spin text-success-1' />
          </div>
        )}

        {projectStore.historyData && (
          <UsdValueChart data={projectStore.historyData} minHeight={250} />
        )}
      </Card>
    </div>
  );
});

export default ProjectAnalytics;
