import SnipaRoutes from 'routes';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'providers/AuthProvider';
import { Navigate } from 'react-router-dom';

const NavigateToMainProfile = observer(() => {
  const authStore = useAuthStore();

  return (
    <Navigate
      to={authStore.account?.address ?? SnipaRoutes.index.path}
      replace
    />
  );
});

export default NavigateToMainProfile;
