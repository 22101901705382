import './index.css';
import BigNumber from 'bignumber.js';
import { useCallback, useMemo } from 'react';
import { bigNumberToStr } from 'utils/strings';
import { NetworkModel } from 'models/portfolio/NetworkModel';
import { ProjectModel } from 'models/portfolio/ProjectModel';
import { WalletModel } from 'models/portfolio/WalletModel';
import { useStaticData } from 'providers/StaticDataStoreProvider';
import Card from 'components/core/card';

const Network = ({ data }: { data: NetworkModel }) => {
  const staticData = useStaticData();

  return (
    <div className='w-full p-2 text-sm flex flex-row items-center gap-2'>
      <div
        className='w-7 h-7 shrink-0 bg-cover'
        style={{
          backgroundImage: `url(${staticData.getNetworkLogo(
            data.id,
            data.type
          )})`,
        }}
      ></div>
      <div className='flex flex-col'>
        <div>
          Assets on{' '}
          <a
            className='font-semibold underline'
            href={data.link}
            target='_blank'
            rel='noreferrer'
          >
            {data.name}
          </a>
        </div>
        <div className='font-bold'>
          ${bigNumberToStr(data.totalUsdValue, 0)}
        </div>
      </div>
    </div>
  );
};

interface AssetData {
  id: string;
  name: string;
  usdValue: BigNumber;
  imgSrc: string;
}

const Asset = ({ data }: { data: AssetData }) => {
  const scrollToAsset = useCallback(() => {
    document.getElementById(data.id)?.scrollIntoView();
  }, [data.id]);

  return (
    <div
      className='w-full p-2 text-sm rounded-md cursor-pointer portfolio_asset_background'
      onClick={scrollToAsset}
    >
      <div className='flex items-center'>
        {data.imgSrc && (
          <div className='shrink-0 mr-2'>
            <img className='w-6 h-6' src={data.imgSrc} alt='' />
          </div>
        )}
        <div className='flex flex-col'>
          <span className='portfolio_asset_text'>{data.name}</span>
          <span className='font-semibold'>
            ${bigNumberToStr(data.usdValue, 0)}
          </span>
        </div>
      </div>
    </div>
  );
};

const Summary = ({
  networks,
  projects,
  wallet,
}: {
  networks?: NetworkModel[];
  projects?: ProjectModel[];
  wallet?: WalletModel;
}) => {
  const staticData = useStaticData();

  const data = useMemo(() => {
    const walletAsset = {
      id: 'wallet',
      name: 'Wallet',
      usdValue: wallet?.totalUsdValue ?? new BigNumber(0),
      imgSrc: staticData.getProjectLogo('wallet'),
    };

    const projectAssets =
      projects?.map((x) => ({
        id: x.id,
        name: x.name,
        usdValue: x.totalUsdValue,
        imgSrc: staticData.getProjectLogo(x.id),
      })) ?? [];

    return [walletAsset, ...projectAssets];
  }, [projects, staticData, wallet?.totalUsdValue]);

  if (!projects && !wallet) {
    return null;
  }

  return (
    <Card className='!bg-primaryBg-3 flex flex-col gap-2'>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
        {networks?.map((network) => (
          <div key={network.name}>
            <Network data={network} />
          </div>
        ))}
      </div>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4'>
        {data.map((asset) => (
          <div key={asset.name}>
            <Asset data={asset} />
          </div>
        ))}
      </div>
    </Card>
  );
};

export default Summary;
