import Portfolio from 'components/portfolio';
import { observer } from 'mobx-react-lite';
import { useProfilePortfolioStore } from '../providers/ProfilePortfolioStoreProvider';

const ProfilePortfolio = observer(() => {
  const profilePortfolioStore = useProfilePortfolioStore();

  return <Portfolio networks={profilePortfolioStore.networks} />;
});

export default ProfilePortfolio;
