import Container from 'components/core/container';
import InputField from 'components/core/input-field';
import { observer } from 'mobx-react-lite';
import { useExchangeKeyStores } from '../providers/ExchangeKeyStoresProvider';
import Exchange from './Exchange';
import ExchangeKeyCard from './ExchangeKeyCard';
import ExchangeKeyActions from './ExchangeKeyActions';
import NewExchangeKeyCard from './new-exchange-key/NewExchangeKeyCard';

const ExchangeKeyList = observer(() => {
  const { exchangeKeyListStore } = useExchangeKeyStores();

  if (!exchangeKeyListStore.exchangeKeys) {
    return null;
  }

  return (
    <Container className='my-6 md:my-10'>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3'>
        {exchangeKeyListStore.exchangeKeys.map((item) => (
          <ExchangeKeyCard key={item.id}>
            <div className='flex flex-row justify-between items-center'>
              <Exchange exchange={item.exchange} />
              <ExchangeKeyActions id={item.id} />
            </div>
            <InputField className='font-bold' value={item.memo} disabled />
            <InputField className='text-base' value={item.name} disabled />
          </ExchangeKeyCard>
        ))}

        <NewExchangeKeyCard />
      </div>
    </Container>
  );
});

export default ExchangeKeyList;
