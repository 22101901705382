import { Api } from 'api';
import { minBy, isEqual } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { TransactionHistoryModel } from 'models/portfolio/TransactionHistoryModel';
import { StaticDataStore } from 'stores/StaticDataStore';

const historyLimitPerFetch = 20;

export class TransactionHistoryStore {
  constructor(private api: Api<unknown>, private staticData: StaticDataStore) {
    makeAutoObservable(this);
  }

  private addresses?: string[];

  loading = false;
  historyItems?: TransactionHistoryModel[];
  canLoadMore = true;

  async init(addresses: string[]) {
    const currentAddresses = this.addresses ? [...this.addresses].sort() : [];
    const newAddresses = [...addresses].sort();

    if (isEqual(newAddresses, currentAddresses)) {
      return;
    }

    runInAction(() => {
      this.addresses = newAddresses;
    });

    this.refresh();
  }

  async refresh() {
    runInAction(() => {
      this.loading = false;
      this.historyItems = undefined;
      this.canLoadMore = true;
    });

    this.fetch();
  }

  async fetchMore() {
    const oldestItem = minBy(this.historyItems, (x) => x.timestamp);

    this.fetch(oldestItem?.timestamp);
  }

  private async fetch(before?: number | undefined | null) {
    if (!this.addresses) {
      return;
    }

    try {
      runInAction(() => {
        this.loading = true;
      });

      const result = await this.api.history.history({
        userAddresses: this.addresses,
        before: before ?? null,
        limit: historyLimitPerFetch,
      });

      runInAction(() => {
        this.historyItems = (this.historyItems ?? []).concat(
          result.data.items.map(
            (x) =>
              new TransactionHistoryModel(
                x,
                x.projectId
                  ? {
                      name: this.staticData.getProject(x.projectId)?.name,
                      logo: this.staticData.getProjectLogo(x.projectId),
                    }
                  : undefined
              )
          )
        );
        this.canLoadMore = result.data.canLoadMore;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}
