import { BundleAccountDTO } from 'api';

export class BundleAccountModel {
  address: string;
  memo?: string;

  constructor(dto: BundleAccountDTO) {
    this.address = dto.address;
    this.memo = dto.memo ?? undefined;
  }
}
