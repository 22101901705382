const ProjectCardTitle = ({ title }: { title: string }) => {
  return (
    <div className='flex items-center mb-4'>
      <div className='flex items-center bg-success-1 text-dark rounded-xl px-6 py-1 text-sm font-bold'>
        {title}
      </div>
    </div>
  );
};

export default ProjectCardTitle;
