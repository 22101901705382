import { ProjectWithStatsModel } from '../models/ProjectWithStatsModel';
import { useStaticData } from 'providers/StaticDataStoreProvider';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { bigNumberToStr } from 'utils/strings';
import Card from 'components/core/card';

const ProjectsListCards = ({ data }: { data: ProjectWithStatsModel[] }) => {
  const staticData = useStaticData();

  const sortedData = useMemo(
    () =>
      [...data].sort(
        (x, y) => -x.totalTvlUsdValue.comparedTo(y.totalTvlUsdValue)
      ),
    [data]
  );

  return (
    <div className='flex flex-col gap-3'>
      {sortedData.map((item, index) => (
        <NavLink key={index} to={item.id} className=''>
          <Card>
            <div className='grid grid-cols-2 gap-2'>
              <div className='col-span-2'>
                <div className='flex items-center gap-3'>
                  <img
                    className='w-8 h-8'
                    src={staticData.getProjectLogo(item.id)}
                    alt=''
                  />

                  <span className='font-bold'>{item.name}</span>
                </div>
              </div>
              <div>
                <span className='text-secondary-1'>Total users</span>
                <div className='text-sm'>{item.totalUsers}</div>
              </div>
              <div>
                <span className='text-secondary-1'>Total TVL</span>
                <div className='text-sm font-bold'>
                  {bigNumberToStr(item.totalTvlUsdValue, 0)}$
                </div>
              </div>
            </div>
          </Card>
        </NavLink>
      ))}
    </div>
  );
};
export default ProjectsListCards;
