import SnipaRoutes from 'routes';
import { useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useApi } from 'providers/ApiProvider';
import { useStaticData } from 'providers/StaticDataStoreProvider';
import { ProjectStore } from '../stores/ProjectStore';
import { showAnalytics } from 'utils/beta';
import NotFound from 'components/layout/NotFound';

const projectStoreContext = createContext<ProjectStore>({} as ProjectStore);

export const ProvideProjectStore = ({
  projectId,
  children,
}: {
  projectId: string;
  children: JSX.Element | JSX.Element[];
}) => {
  const api = useApi();
  const staticData = useStaticData();
  const store = useLocalObservable(() => new ProjectStore(api, staticData));

  const currentProject = useMemo(
    () => staticData.allProjects.find((x) => x.id === projectId),
    [projectId, staticData.allProjects]
  );

  useEffect(() => {
    if (!currentProject) {
      return;
    }

    store.fetchProject(currentProject.id);

    if (showAnalytics) {
      store.fetchHistory(currentProject.id);
    }
  }, [store, currentProject]);

  if (!currentProject) {
    return (
      <NotFound
        btn={{ text: 'Go to projects', link: `/${SnipaRoutes.projects.path}` }}
      />
    );
  }

  return (
    <projectStoreContext.Provider value={store}>
      {children}
    </projectStoreContext.Provider>
  );
};

export const useProjectStore = () => {
  return useContext(projectStoreContext);
};
