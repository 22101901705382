import { ProjectUserDTO } from 'api';
import BigNumber from 'bignumber.js';

export class ProjectUserModel {
  address: string;
  networth: BigNumber;
  updatedAt: number | null;
  tvlSharePercent: BigNumber;
  position: number;

  constructor(
    dto: ProjectUserDTO,
    projectTotalTvl: BigNumber,
    position: number
  ) {
    this.address = dto.userAddress;
    this.networth = new BigNumber(dto.usdValue);
    this.updatedAt = dto.updated;
    this.position = position;

    this.tvlSharePercent = !projectTotalTvl.isEqualTo(0)
      ? this.networth.dividedBy(projectTotalTvl).multipliedBy(100)
      : new BigNumber(0);
  }
}
