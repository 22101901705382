import { useStaticData } from 'providers/StaticDataStoreProvider';
import { useMemo } from 'react';
import { useExchangeKeyStores } from '../providers/ExchangeKeyStoresProvider';

const Exchange = ({ exchange }: { exchange: string }) => {
  const staticData = useStaticData();
  const { exchangeStore } = useExchangeKeyStores();

  const exchangeName = useMemo(() => {
    return exchangeStore.getExchange(exchange)?.name;
  }, [exchangeStore, exchange]);

  return (
    <div className='flex items-center gap-2'>
      <img
        className='w-6 h-6'
        src={staticData.getNetworkLogo(exchange, 'exchange')}
        alt=''
      />

      <span className='font-bold'>{exchangeName}</span>
    </div>
  );
};

export default Exchange;
