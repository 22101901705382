import BigNumber from 'bignumber.js';
import { NetworkModel } from 'models/portfolio/NetworkModel';

export class BundlePortfolioModel {
  networks: NetworkModel[];
  totalUsd: BigNumber;

  constructor(networks: NetworkModel[]) {
    this.networks = networks;
    this.totalUsd = networks
      .map((x) => x.totalUsdValue)
      .reduce((x, y) => x.plus(y));
  }
}
