import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';
import { useApi } from 'providers/ApiProvider';
import { ExchangeKeyListStore } from 'stores/exchange-key/ExchangeKeyListStore';
import { ExchangeStore } from 'stores/exchange-key/ExchangeStore';

type BundleExchangeKeyStores = {
  exchangeStore: ExchangeStore;
  exchangeKeyListStore: ExchangeKeyListStore;
};

const bundleExchangeKeyStoresContext = createContext<BundleExchangeKeyStores>(
  {} as BundleExchangeKeyStores
);

export const ProvideBundleExchangeKeyStores = observer(
  ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const api = useApi();
    const exchangeStore = useLocalObservable(() => new ExchangeStore(api));
    const exchangeKeyListStore = useLocalObservable(
      () => new ExchangeKeyListStore(api)
    );

    useEffect(() => {
      exchangeStore.init();
    }, [exchangeStore]);

    useEffect(() => {
      exchangeKeyListStore.fetchExchangeKeys();
    }, [exchangeKeyListStore]);

    if (!exchangeStore.initialized) {
      return null;
    }

    if (!exchangeKeyListStore.exchangeKeys) {
      return null;
    }

    return (
      <bundleExchangeKeyStoresContext.Provider
        value={{ exchangeStore, exchangeKeyListStore }}
      >
        {children}
      </bundleExchangeKeyStoresContext.Provider>
    );
  }
);

export const useBundleExchangeKeyStores = () => {
  return useContext(bundleExchangeKeyStoresContext);
};
