import Table from 'components/core/table';
import TransactionHistoryToken from './TransactionHistoryToken';
import TransactionHistoryCards from './TransactionHistoryCards';
import { useMemo } from 'react';
import { useMdMediaQuery } from 'utils/responsiveness';
import { Column } from 'react-table';
import { cutString, unixDateToStr } from 'utils/strings';
import { TransactionHistoryModel } from 'models/portfolio/TransactionHistoryModel';
import { NavLink } from 'react-router-dom';
import SnipaRoutes from 'routes';

const TransactionHistoryTable = ({
  data,
  showUserAddress,
}: {
  data: TransactionHistoryModel[];
  showUserAddress: boolean;
}) => {
  const columns = useMemo<Column<TransactionHistoryModel>[]>(
    () => [
      {
        Header: 'Transaction',
        accessor: 'transactionHash',
        Cell: ({ row }) => (
          <div className='flex flex-col'>
            <span className='font-bold'>
              {unixDateToStr(row.original.timestamp, 'DD.MM.YYYY HH:mm')}
            </span>
            <a
              href={row.original.transactionUrl}
              target='_blank'
              rel='noopener noreferrer'
              className='text-secondary-1 underline'
            >
              <span>{cutString(row.original.transactionHash, 10, 4)}</span>
            </a>
          </div>
        ),
        disableSortBy: true,
        className: 'w-48',
      },
      {
        Header: 'Project',
        accessor: 'project',
        Cell: ({ row }) =>
          row.original.project ? (
            <div className='flex gap-1'>
              <img src={row.original.project.logo} className='w-5 h-5' alt='' />
              <span>{row.original.project.name}</span>
            </div>
          ) : (
            <></>
          ),
        disableSortBy: true,
        className: 'w-36',
      },
      {
        Header: 'Operation',
        accessor: 'operation',
        Cell: ({ row }) => (
          <div className='flex flex-col'>
            <span className='font-bold'>{row.original.operation}</span>

            {showUserAddress && (
              <span className='flex gap-1 text-secondary-1'>
                <span className='text-secondary-1'>By:</span>
                <NavLink
                  to={`/${SnipaRoutes.profile.path}/${row.original.userAddress}`}
                  className='underline'
                >
                  {cutString(row.original.userAddress, 10, 4)}
                </NavLink>
              </span>
            )}

            {row.original.fromBlockhain && (
              <span className='flex gap-1'>
                <span className='text-secondary-1'>From:</span>
                <span>{row.original.fromBlockhain}</span>
              </span>
            )}

            {row.original.toBlockchain && (
              <span className='flex gap-1'>
                <span className='text-secondary-1'>To:</span>
                <span>{row.original.toBlockchain}</span>
              </span>
            )}

            {row.original.counterParty && (
              <span className='flex gap-1 text-secondary-1'>
                <span>
                  {row.original.counterParty.isSender === true ? 'From' : 'To'}:
                </span>
                <a
                  className='underline'
                  href={row.original.counterParty.link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {cutString(row.original.counterParty.address, 8, 4)}
                </a>
              </span>
            )}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Amount',
        accessor: 'tokens',
        Cell: ({ row }) => (
          <div className='flex flex-col gap-1'>
            {row.original.tokens.map((x, i) => (
              <TransactionHistoryToken
                key={i}
                symbol={x.symbol}
                rootAddress={x.rootAddress}
                amount={x.amount}
                usdValue={x.usdValue}
                isNative={x.isNative}
                network={x.network}
              />
            ))}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Fee',
        accessor: 'fee',
        Cell: ({ row }) => (
          <div className='flex flex-col gap-1'>
            {row.original.fee?.map((x, i) => (
              <TransactionHistoryToken
                key={i}
                symbol={x.symbol}
                rootAddress={x.rootAddress}
                amount={x.amount}
                usdValue={x.usdValue}
                isNative={x.isNative}
                network={x.network}
              />
            ))}
          </div>
        ),
        disableSortBy: true,
        className: 'min-w-[6rem]',
      },
    ],
    [showUserAddress]
  );

  const isDesktop = useMdMediaQuery();

  return (
    <div className='flex flex-col gap-4'>
      {isDesktop ? (
        <Table columns={columns} data={data} />
      ) : (
        <TransactionHistoryCards
          data={data}
          showUserAddress={showUserAddress}
        />
      )}
    </div>
  );
};

export default TransactionHistoryTable;
