import Container from 'components/core/container';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useProjectListStore } from '../providers/ProjectListStoreProvider';
import ProjectsListTable from './ProjectsListTable';

const ProjectsList = observer(() => {
  const projectListStore = useProjectListStore();

  useEffect(() => {
    projectListStore.fetchProjects();
  }, [projectListStore]);

  if (!projectListStore.projects) {
    return null;
  }

  return (
    <Container className='my-6 md:my-10'>
      {projectListStore.projects.length > 0 && (
        <ProjectsListTable projects={projectListStore.projects} />
      )}
    </Container>
  );
});

export default ProjectsList;
