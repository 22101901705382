import { useCallback } from 'react';
import { ExchangeKeyModel } from 'models/exchange-key/ExchangeKeyModel';
import * as yup from 'yup';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/core/input-field';
import Button from 'components/core/button';
import { ExchangeKeyMemoValidationSchema } from '../../models/ExchangeKeyMemoValidationSchema';

type FormFields = {
  exchangeKeyMemo: string;
};

const ExchangeKeyFormSchema = yup.object({
  exchangeKeyMemo:
    ExchangeKeyMemoValidationSchema.required('Field is required'),
});

const EditExchangeKey = ({
  initValue,
  onConfirm,
  onCancel,
}: {
  initValue: ExchangeKeyModel;
  onConfirm: (data: ExchangeKeyModel) => void;
  onCancel: () => void;
}) => {
  const { handleSubmit, control } = useForm<FormFields>({
    resolver: yupResolver(ExchangeKeyFormSchema),
    mode: 'onChange',
    defaultValues: { exchangeKeyMemo: initValue?.memo },
  });

  const applyForm = useCallback<SubmitHandler<FormFields>>(
    (data) => {
      onConfirm({
        id: initValue.id,
        memo: data.exchangeKeyMemo,
        name: initValue.name,
        exchange: initValue.exchange,
      });
    },
    [onConfirm, initValue]
  );

  const title = 'Edit exchange key';
  const description = 'Enter a new memo for the exchange key';

  return (
    <form className='flex flex-col' onSubmit={handleSubmit(applyForm)}>
      <div className='text-base'>{title}</div>
      <div className='text-secondary-1 text-sm my-2'>{description}</div>
      <Controller
        control={control}
        name='exchangeKeyMemo'
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <InputField
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            error={error?.message}
          />
        )}
      ></Controller>
      <div className='flex justify-between mt-6 flex-row-reverse'>
        <Button variant='primary' className='w-28' type='submit'>
          <span>Save</span>
        </Button>

        <Button
          variant='secondary'
          className='w-28'
          type='button'
          onClick={onCancel}
        >
          <span>Cancel</span>
        </Button>
      </div>
    </form>
  );
};

export default EditExchangeKey;
