import { useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext } from 'react';
import { useApi } from 'providers/ApiProvider';
import { TransactionHistoryStore } from 'stores/transaction-history/TransactionHistoryStore';
import { useStaticData } from 'providers/StaticDataStoreProvider';

const transactionHistoryStoreContext = createContext<TransactionHistoryStore>(
  {} as TransactionHistoryStore
);

export const ProvideTransactionHistoryStore = ({ children }: any) => {
  const api = useApi();
  const staticData = useStaticData();
  const store = useLocalObservable(
    () => new TransactionHistoryStore(api, staticData)
  );

  return (
    <transactionHistoryStoreContext.Provider value={store}>
      {children}
    </transactionHistoryStoreContext.Provider>
  );
};

export const useTransactionHistoryStore = () => {
  return useContext(transactionHistoryStoreContext);
};
