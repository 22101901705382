import { Api } from 'api';
import BigNumber from 'bignumber.js';
import { makeAutoObservable, runInAction } from 'mobx';
import { AnalyticsTokenModel } from 'models/portfolio/AnalyticsTokenModel';
import { ProjectUserModel } from 'modules/project/models/ProjectUserModel';
import { StaticDataStore } from 'stores/StaticDataStore';
import { getMaxPage, getSkip } from 'utils/pagination';
import { ProjectWithStatsModel } from '../models/ProjectWithStatsModel';

const usersPageSize = 10;

export class ProjectStore {
  constructor(private api: Api<unknown>, private staticData: StaticDataStore) {
    makeAutoObservable(this);
  }

  project?: ProjectWithStatsModel;

  users?: { items: ProjectUserModel[]; total: number; maxPage: number };

  historyData?: AnalyticsTokenModel[];

  async fetchProject(projectId: string) {
    const project = this.staticData.allProjects.find((x) => x.id === projectId);

    if (!project) {
      return;
    }

    const result = await this.api.projects.projectWithStats({ id: projectId });

    runInAction(() => {
      this.project = new ProjectWithStatsModel(result.data);
    });
  }

  async fetchUsers(page: number) {
    if (!this.project) {
      return;
    }

    const skip = getSkip(page, usersPageSize);

    const result = await this.api.users.projectUsers({
      project: this.project.id,
      pagination: { take: usersPageSize, skip: skip },
    });

    runInAction(() => {
      this.users = {
        items: result.data.items.map(
          (userDto, index) =>
            new ProjectUserModel(
              userDto,
              this.project?.totalTvlUsdValue ?? new BigNumber(0),
              1 + skip + index
            )
        ),
        total: result.data.total,
        maxPage: getMaxPage(result.data.total, usersPageSize),
      };
    });
  }

  async fetchHistory(projectId: string) {
    const result = await this.api.history.projectHistory({
      projectIds: [projectId],
      timeSecBefore: null,
      timeSecFrom: null,
    });

    runInAction(() => {
      this.historyData = result.data.items.map(
        (x) => new AnalyticsTokenModel(x)
      );
    });
  }
}
