import Card from 'components/core/card';
import Tokens from 'components/core/tokens';
import { TokenBalanceModel } from 'models/portfolio/TokenBalanceModel';
import { useMemo } from 'react';
import { bigNumberToStr } from 'utils/strings';

const WalletCards = ({ assets }: { assets: TokenBalanceModel[] }) => {
  const sortedAssets = useMemo(
    () => [...assets].sort((x, y) => -x.total.comparedTo(y.total)),
    [assets]
  );

  return (
    <div className='flex flex-col gap-3'>
      {sortedAssets.map((item, index) => (
        <Card key={index}>
          <div className='grid grid-cols-2 gap-2'>
            <div>
              <Tokens tokens={[item]} />
            </div>
            <div className='flex flex-col'>
              <span className='text-secondary-1'>USD Value</span>
              <span className='text-sm font-bold'>
                ${bigNumberToStr(item.total, 0)}
              </span>
            </div>
            <div className='flex flex-col'>
              <span className='text-secondary-1'>Amount</span>
              <span className='text-sm'>
                {bigNumberToStr(item.amount, 3, { useShortNotation: true })}
              </span>
            </div>
            <div className='flex flex-col'>
              <span className='text-secondary-1'>Price</span>
              <span className='text-sm'>
                ${bigNumberToStr(item.usdPrice, 4)}
              </span>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};
export default WalletCards;
