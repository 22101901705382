import Tokens from 'components/core/tokens';
import TokenValues from 'components/core/token-values';
import { PoolModel } from 'models/portfolio/PoolModel';
import { SupplyTokenModel } from 'models/portfolio/SupplyTokenModel';
import { bigNumberToStr, unixDateToStr } from 'utils/strings';
import ProjectCardTitle from '../project/ProjectCardTitle';
import Card from 'components/core/card';
import { VeTokenModel } from 'models/portfolio/VeTokenModel';
import { useMemo, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { LockedDepositModel } from 'models/portfolio/LockedDepositModel';

const itemsToTake = 3;

const UnlockSchedule = ({ data }: { data: LockedDepositModel[] }) => {
  const [cursor, setCursor] = useState(0);

  const itemToDisplay = useMemo(
    () => data.slice(cursor, cursor + itemsToTake),
    [cursor, data]
  );

  return (
    <div className='flex items-center justify-center gap-x-2'>
      <div className='flex-shrink-0 w-5'>
        {cursor > 0 && (
          <BsChevronLeft
            className='text-lg'
            onClick={() => setCursor((prev) => prev - 1)}
          />
        )}
      </div>
      <div className='flex gap-x-3'>
        {itemToDisplay.map((x) => (
          <div key={x.unlockTime} className='flex flex-col text-sm'>
            <span>{bigNumberToStr(x.percent, 2)}%</span>
            <span className='text-secondary-1'>
              {unixDateToStr(x.unlockTime, 'DD.MM.YYYY')}
            </span>
          </div>
        ))}
      </div>
      <div className='flex-shrink-0 w-5'>
        {cursor + itemsToTake < data.length && (
          <BsChevronRight
            className='text-lg'
            onClick={() => setCursor((prev) => prev + 1)}
          />
        )}
      </div>
    </div>
  );
};

const EscrowingCards = ({ poolInfo }: { poolInfo: PoolModel[] }) => {
  return (
    <div className='flex flex-col gap-3'>
      {poolInfo.map((item, index) => (
        <Card key={index}>
          <ProjectCardTitle title='Vote Escrowed' />

          <div className='grid grid-cols-2'>
            <div>
              <Tokens tokens={item.supplyTokenList} />
            </div>
            <div>
              <span className='text-secondary-1'>USD Value</span>
              <div className='text-sm font-bold'>
                ${bigNumberToStr(item.totalUsdValue, 0)}
              </div>
            </div>
            <div className='col-span-2'>
              <span className='text-secondary-1'>Balance</span>
              <TokenValues
                tokens={item.supplyTokenList.map((token: SupplyTokenModel) => ({
                  amount: token.amount,
                  symbol: token.symbol,
                  usdValue: token.usdValue,
                }))}
              />
              <TokenValues
                tokens={item.veTokenList?.map((token: VeTokenModel) => ({
                  amount: token.amount,
                  symbol: token.symbol,
                }))}
              />
            </div>

            {item.unlockSchedule.length > 1 && (
              <div className='col-span-2 mt-4'>
                <UnlockSchedule data={item.unlockSchedule} />
              </div>
            )}

            {item.unlockSchedule.length === 1 && (
              <div className='col-span-2 mt-4'>
                <h1 className='text-secondary-1'>Unlock</h1>
                {unixDateToStr(item.unlockSchedule[0].unlockTime, 'DD.MM.YYYY')}
              </div>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
};
export default EscrowingCards;
