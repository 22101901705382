import { useMemo } from 'react';
import { useMdMediaQuery } from 'utils/responsiveness';
import Table from 'components/core/table';
import StakingCards from './StakingCards';
import TokenValues from 'components/core/token-values';
import Tokens from 'components/core/tokens';
import { bigNumberToStr, cutString } from 'utils/strings';
import { Column } from 'react-table';
import { PoolModel } from 'models/portfolio/PoolModel';
import { SupplyTokenModel } from 'models/portfolio/SupplyTokenModel';
import { StakingRewardModel } from 'models/portfolio/StakingRewardModel';
import { every } from 'lodash';

const StakingTable = ({ poolInfo }: { poolInfo: PoolModel[] }) => {
  const hideRewardCol = useMemo(
    () => every(poolInfo, (x) => x.stakingRewardList == null),
    [poolInfo]
  );

  const columns = useMemo<Column<PoolModel>[]>(() => {
    const cols = [
      {
        Header: 'Assets',
        accessor: 'type',
        Cell: ({ row }) => <Tokens tokens={row.original.supplyTokenList} />,
        disableSortBy: true,
      },
      {
        Header: 'Address',
        accessor: 'address',
        Cell: ({ row }) => (
          <div className='truncate max-w-[200px]'>
            {cutString(row.original.address, 8, 4)}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Balance',
        accessor: 'supplyTokenList',
        Cell: ({ row }) => (
          <TokenValues
            tokens={row.original.supplyTokenList.map(
              (token: SupplyTokenModel) => ({
                amount: token.amount,
                symbol: token.symbol,
                usdValue: token.usdValue,
              })
            )}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Reward',
        accessor: 'stakingRewardList',
        Cell: ({ row }) => (
          <TokenValues
            tokens={row.original.stakingRewardList?.map(
              (token: StakingRewardModel) => ({
                amount: token.amount,
                symbol: token.symbol,
                usdValue: token.usdValue,
              })
            )}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'USD Value',
        accessor: 'totalUsdValue',
        Cell: ({ row }) => (
          <span className='font-bold'>
            ${bigNumberToStr(row.original.totalUsdValue, 0)}
          </span>
        ),
        sortType: (rowA, rowB) => {
          return rowA.original.totalUsdValue.comparedTo(
            rowB.original.totalUsdValue
          );
        },
        className: 'w-48',
      },
    ] as Column<PoolModel>[];

    if (hideRewardCol === true) {
      const rewardCol = cols.findIndex(
        (x) => x.accessor === 'stakingRewardList'
      );

      if (rewardCol > -1) {
        cols.splice(rewardCol, 1);
      }
    }

    return cols;
  }, [hideRewardCol]);

  const defaultSort = useMemo(() => ({ id: 'totalUsdValue', desc: true }), []);
  const isDesktop = useMdMediaQuery();

  return isDesktop ? (
    <Table
      columns={columns}
      data={poolInfo}
      title='Staking'
      defaultSortBy={defaultSort}
    />
  ) : (
    <StakingCards poolInfo={poolInfo} />
  );
};

export default StakingTable;
