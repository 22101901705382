import { WalletModel } from 'models/portfolio/WalletModel';
import { useStaticData } from 'providers/StaticDataStoreProvider';
import ProjectTitle from '../project/ProjectTitle';
import WalletTable from './WalletTable';

const Wallet = ({ data }: { data?: WalletModel }) => {
  const staticData = useStaticData();

  if (!data || data.tokens.length === 0) {
    return null;
  }

  return (
    <div id='wallet' className='flex flex-col gap-6'>
      <ProjectTitle
        logo={staticData.getProjectLogo('wallet')}
        name='Wallet'
        usdValue={data.totalUsdValue}
      />

      <WalletTable assets={data.tokens} />
    </div>
  );
};

export default Wallet;
