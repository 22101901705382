import { Routes, Route } from 'react-router-dom';
import NavigateToMainProfile from './components/NavigateToMainProfile';
import Profile from './components/Profile';

const ProfilePage = () => {
  return (
    <Routes>
      <Route path=':address/*' element={<Profile />} />
      <Route path='*' element={<NavigateToMainProfile />} />
    </Routes>
  );
};

export default ProfilePage;
