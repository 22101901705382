import { Api } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';
import { StaticDataStore } from 'stores/StaticDataStore';
import { ProjectWithStatsModel } from '../models/ProjectWithStatsModel';

export class ProjectListStore {
  constructor(private api: Api<unknown>, private staticData: StaticDataStore) {
    makeAutoObservable(this);
  }

  projects?: ProjectWithStatsModel[];

  async fetchProjects() {
    const networks = this.staticData.getNetworks();

    const result = await Promise.all(
      networks.map((x) => this.api.projects.projectListWithStats({ id: x.id }))
    );

    runInAction(() => {
      this.projects = result
        .flatMap((x) => x.data)
        .map((x) => new ProjectWithStatsModel(x));
    });
  }
}
