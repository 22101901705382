import { Routes, Route, Navigate } from 'react-router-dom';
import { ProvideProjectListStore } from './providers/ProjectListStoreProvider';
import ProjectDetails from './components/ProjectDetails';
import ProjectsList from './components/ProjectsList';

const ProjectPage = () => {
  return (
    <ProvideProjectListStore>
      <Routes>
        <Route index element={<ProjectsList />} />
        <Route path=':projectId/*' element={<ProjectDetails />} />
        <Route path='*' element={<Navigate to='' />} />
      </Routes>
    </ProvideProjectListStore>
  );
};

export default ProjectPage;
