import './index.css';
import { useState, useEffect } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

type PaginationProps = {
  currentPage: number;
  maxPage: number;
  onChange?: (page: number) => void;
};

const defaultDelayMs = 250;

const Pagination = ({ currentPage, maxPage, onChange }: PaginationProps) => {
  const [numValue, setNumValue] = useState(currentPage);
  const [strValue, setStrValue] = useState(currentPage.toString());

  const onValueChanged = (strValue: string) => {
    if (!strValue || strValue === '') {
      setStrValue('');
      return;
    }

    const numValue = Number(strValue);

    if (numValue <= 0) {
      setNumValue(1);
      setStrValue('1');
      return;
    }

    if (numValue > maxPage) {
      setNumValue(maxPage);
      setStrValue(maxPage.toString());
      return;
    }

    setNumValue(numValue);
    setStrValue(strValue);
  };

  useEffect(() => {
    if (currentPage === numValue) {
      return;
    }

    const timeout = setTimeout(() => {
      onChange?.(numValue);
    }, defaultDelayMs);

    return () => clearTimeout(timeout);
  }, [currentPage, numValue, onChange]);

  if (maxPage <= 1) {
    return null;
  }

  return (
    <div className='flex justify-center items-center'>
      <button
        className='pagination_button'
        onClick={() => onValueChanged((numValue - 1).toString())}
        disabled={numValue <= 1}
      >
        <BsChevronLeft className='text-lg' />
      </button>
      <span>Page</span>
      <input
        className='pagination_input'
        type='number'
        value={strValue}
        onChange={(event) => onValueChanged(event.target.value)}
      />
      <span>of {maxPage}</span>
      <button
        className='pagination_button'
        onClick={() => onValueChanged((numValue + 1).toString())}
        disabled={numValue >= maxPage}
      >
        <BsChevronRight className='text-lg' />
      </button>
    </div>
  );
};

export default Pagination;
