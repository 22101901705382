import './index.css';
import Container from 'components/core/container';

const Link = ({ href, text }: { href: string; text: string }) => {
  return (
    <div>
      <a
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        className='hover:text-success-1'
      >
        {text}
      </a>
    </div>
  );
};

const Footer = () => {
  return (
    <Container className='mt-auto mb-6 flex flex-col md:flex-row justify-between gap-4 md:gap-24'>
      <div className='w-full'>
        <hr className='footer_border opacity-100 mb-4' />
        <span className='text-secondary-1'>Contact us:</span>
        <Link
          href={`mailto:hello@snipa.finance`}
          text={'hello@snipa.finance'}
        />
      </div>
      <div className='w-full'>
        <hr className='footer_border opacity-100 mb-4 hidden md:block' />
        <span className='text-secondary-1'>Follow us:</span>
        <div className='flex flex-col md:flex-row justify-start gap-1 md:gap-6'>
          <Link href='https://twitter.com/SnipaFinance' text='Twitter' />
          <Link href='https://t.me/snipa_news' text='Telegram channel' />
          <Link href='https://t.me/snipa_chat' text='Telegram group' />
        </div>
      </div>
    </Container>
  );
};
export default Footer;
