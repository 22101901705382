import Table from 'components/core/table';
import { useMdMediaQuery } from 'utils/responsiveness';
import { ProjectWithStatsModel } from '../models/ProjectWithStatsModel';
import { useStaticData } from 'providers/StaticDataStoreProvider';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Column } from 'react-table';
import { bigNumberToStr } from 'utils/strings';
import ProjectsListCards from './ProjectsListCards';

const ProjectsListTable = ({
  projects,
}: {
  projects: ProjectWithStatsModel[];
}) => {
  const staticData = useStaticData();

  const columns = useMemo<Column<ProjectWithStatsModel>[]>(
    () => [
      {
        Header: 'Project',
        accessor: 'name',
        Cell: ({ row }) => (
          <NavLink to={row.original.id}>
            <div className='flex items-center gap-x-4'>
              <img
                className='w-6 h-6'
                src={staticData.getProjectLogo(row.original.id)}
                alt=''
              />

              <span>{row.original.name}</span>
            </div>
          </NavLink>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Total users',
        accessor: 'totalUsers',
        Cell: ({ row }) => <span>{row.original.totalUsers}</span>,
        sortType: (rowA, rowB) => {
          return rowA.original.totalUsers > rowB.original.totalUsers ? 1 : -1;
        },
        className: 'w-40',
      },
      {
        Header: 'Total TVL',
        accessor: 'totalTvlUsdValue',
        Cell: ({ row }) => (
          <span className='font-bold'>
            {bigNumberToStr(row.original.totalTvlUsdValue, 0)}$
          </span>
        ),
        sortType: (rowA, rowB) => {
          return rowA.original.totalTvlUsdValue.comparedTo(
            rowB.original.totalTvlUsdValue
          );
        },
        className: 'w-52',
      },
    ],
    [staticData]
  );

  const defaultSort = useMemo(
    () => ({ id: 'totalTvlUsdValue', desc: true }),
    []
  );

  const isDesktop = useMdMediaQuery();

  return isDesktop ? (
    <Table columns={columns} data={projects} defaultSortBy={defaultSort} />
  ) : (
    <ProjectsListCards data={projects} />
  );
};

export default ProjectsListTable;
