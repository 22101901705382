import Button from 'components/core/button';
import Dropdown from 'components/core/dropdown';
import Table from 'components/core/table';
import { BundleExchangeKeyModel } from 'modules/bundle/models/BundleExchangeKeyModel';
import { useMemo } from 'react';
import { BsThreeDots, BsTrash } from 'react-icons/bs';
import { Column } from 'react-table';
import BundleExchangeKeyName from '../BundleExchangeKeyName';

const ExchangeKeysTable = ({
  data,
  onRemove,
}: {
  data: BundleExchangeKeyModel[];
  onRemove: (address: string) => void;
}) => {
  const columns = useMemo<
    Column<{
      exchange: string;
      id: string;
      memo: string;
      name: string;
      actions: any;
    }>[]
  >(
    () => [
      {
        Header: 'Name',
        accessor: 'memo',
        Cell: ({ row }) => (
          <BundleExchangeKeyName
            exchange={row.original.exchange}
            name={row.original.memo}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'API Key',
        accessor: 'name',
        Cell: ({ row }) => (
          <div className='whitespace-normal'>{row.original.name}</div>
        ),
        disableSortBy: true,
        className: 'hidden md:table-cell',
      },
      {
        Header: () => <></>,
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className='flex justify-center'>
            <Dropdown
              menuButton={
                <Button variant='info' className='!p-1'>
                  <BsThreeDots className='text-sm' />
                </Button>
              }
              actions={[
                {
                  text: 'Remove',
                  onClick: () => onRemove(row.original.id),
                  icon: <BsTrash />,
                  className: 'text-danger-1',
                },
              ]}
            />
          </div>
        ),
        disableSortBy: true,
        className: 'w-16',
      },
    ],
    [onRemove]
  );

  return <Table data={data} columns={columns} />;
};

export default ExchangeKeysTable;
