import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';
import { useBundleStore } from '../../providers/BundleStoreProvider';

const NavigateToBundle = observer(() => {
  const bundleStore = useBundleStore();

  if (!bundleStore.bundles || bundleStore.bundles.length === 0) {
    return null;
  }

  return <Navigate to={bundleStore.bundles[0].id} replace />;
});

export default NavigateToBundle;
