var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CONNECT_EVENT, ERROR_EVENT, EXTENSION_AUTH_EVENT, EXTENSION_WINDOW_CLOSED_EVENT, SELECT_EVENT, } from "../helpers/events";
import { checkIsCurrentBrowser } from "../helpers/utils";
import * as allProviders from "../providers";
import { EventController } from "./EventController";
var sortingArr = ["extension", "mobile", "ios", "android"];
export var getPromiseRaw = function (windowObject, walletId, type) {
    var _a;
    if (type === void 0) { type = "extension"; }
    var promises = {
        venomwallet: {
            extension: function () {
                if (windowObject) {
                    return new Promise(function (resolve, reject) {
                        if (windowObject.__venom) {
                            resolve(windowObject.__venom);
                            return;
                        }
                        var nTries = 0; // число попыток, иначе он будет бесконечно, может это вынести в конфиг
                        var interval = setInterval(function () {
                            if (windowObject.__venom) {
                                clearInterval(interval);
                                resolve(windowObject.__venom);
                            }
                            else if (nTries > 0) {
                                nTries--;
                            }
                            else {
                                clearInterval(interval);
                                reject("Venom wallet is not found");
                            }
                        }, 100);
                    });
                }
                return Promise.reject();
            },
        },
        everwallet: {
            extension: function () {
                if (windowObject) {
                    return new Promise(function (resolve, reject) {
                        if (windowObject.__ever) {
                            resolve(windowObject.__ever);
                            return;
                        }
                        var nTries = 0; // число попыток, иначе он будет бесконечно, может это вынести в конфиг
                        var interval = setInterval(function () {
                            if (windowObject.__ever) {
                                clearInterval(interval);
                                resolve(windowObject.__ever);
                            }
                            else if (nTries > 0) {
                                nTries--;
                            }
                            else {
                                clearInterval(interval);
                                reject("Ever wallet is not found");
                            }
                        }, 100);
                    });
                }
                return Promise.reject();
            },
        },
        oxychatwallet: {
            // todo
            extension: function () {
                if (windowObject) {
                    return new Promise(function (resolve, reject) {
                        if (windowObject.__oxy) {
                            resolve(windowObject.__oxy);
                            return;
                        }
                        var nTries = 0; // число попыток, иначе он будет бесконечно, может это вынести в конфиг
                        var interval = setInterval(function () {
                            if (windowObject.__oxy) {
                                clearInterval(interval);
                                resolve(windowObject.__oxy);
                            }
                            else if (nTries > 0) {
                                nTries--;
                            }
                            else {
                                clearInterval(interval);
                                reject("Ever wallet is not found");
                            }
                        }, 100);
                    });
                }
                return Promise.reject();
            },
        },
    };
    // @ts-ignore
    return (_a = promises[walletId]) === null || _a === void 0 ? void 0 : _a[type];
};
var ProviderController = /** @class */ (function () {
    function ProviderController(options) {
        var _this = this;
        this.eventController = new EventController();
        this.providers = [];
        this.getStandalone = function (walletId) { return __awaiter(_this, void 0, void 0, function () {
            var wallet, standaloneFromUser;
            var _a;
            return __generator(this, function (_b) {
                wallet = (_a = this.providers) === null || _a === void 0 ? void 0 : _a.find(function (provider) { return provider.id === walletId; });
                if (wallet) {
                    standaloneFromUser = wallet === null || wallet === void 0 ? void 0 : wallet.walletWaysToConnect.find(function (way) { return way.type === "extension" && !!way.standalone; });
                    if (standaloneFromUser === null || standaloneFromUser === void 0 ? void 0 : standaloneFromUser.standalone) {
                        return [2 /*return*/, standaloneFromUser.standalone(standaloneFromUser.package, standaloneFromUser.packageOptionsStandalone)];
                    }
                }
                return [2 /*return*/, null];
            });
        }); };
        this.getOptions = function () {
            var options = _this.providers.reduce(function (r, provider) {
                var id = provider.id, walletWaysToConnect = provider.walletWaysToConnect;
                var obj = __assign(__assign({}, provider), { walletWaysToConnect: walletWaysToConnect
                        .map(function (walletWayToConnect) {
                        var connector = walletWayToConnect.connector, connectorId = walletWayToConnect.id;
                        var isShould = _this.shouldDisplayProvider(id);
                        if (isShould && connector)
                            return __assign(__assign({}, walletWayToConnect), { onClick: function () { return _this.connectTo(id, connectorId, connector); } });
                        else
                            return null;
                    })
                        .filter(function (walletWayToConnect) { return !!walletWayToConnect; }) });
                if (obj.walletWaysToConnect.length) {
                    r.push(obj);
                }
                return r;
            }, []);
            return options;
        };
        this.getProvider = function (id) {
            return _this.providers.find(function (provider) { return provider.id === id; });
        };
        this.getAuthTo = function (id, connectorId, authConnector) { return __awaiter(_this, void 0, void 0, function () {
            var providerPackage, providerOptions, options, provider, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.currentProvider = null;
                        providerPackage = this.getProviderOption(id, connectorId, "package");
                        providerOptions = this.getProviderOption(id, connectorId, "packageOptions");
                        options = __assign({}, providerOptions);
                        return [4 /*yield*/, authConnector(providerPackage, options)];
                    case 1:
                        provider = _a.sent();
                        this.currentProvider = (provider === null || provider === void 0 ? void 0 : provider.auth) || null;
                        return [2 /*return*/, provider || null];
                    case 2:
                        error_1 = _a.sent();
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.connectTo = function (id, connectorId, connector) { return __awaiter(_this, void 0, void 0, function () {
            var providerPackage, providerOptions, options, provider, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.currentProvider = null;
                        this.eventController.trigger(SELECT_EVENT, id);
                        providerPackage = this.getProviderOption(id, connectorId, "package");
                        providerOptions = this.getProviderOption(id, connectorId, "packageOptions");
                        options = __assign({}, providerOptions);
                        return [4 /*yield*/, connector(providerPackage, options, {
                                authorizationCompleted: function (_provider) {
                                    _this.eventController.trigger(EXTENSION_AUTH_EVENT, _provider);
                                },
                                extensionWindowClosed: function () {
                                    _this.eventController.trigger(EXTENSION_WINDOW_CLOSED_EVENT);
                                },
                                extensionWindowError: function (error) {
                                    _this.eventController.trigger(ERROR_EVENT, error);
                                },
                            })];
                    case 1:
                        provider = _a.sent();
                        this.currentProvider = provider;
                        this.eventController.trigger(CONNECT_EVENT, provider);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        this.eventController.trigger(ERROR_EVENT, error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var defaultPackageOptions = {
            venomwallet: window
                ? {
                    extension: {
                        forceUseFallback: true,
                        fallback: getPromiseRaw(window, "venomwallet") ||
                            (function () { return Promise.reject("venomwallet fallback error"); }),
                    },
                    standalone: {}, // ?
                }
                : {},
            everwallet: window
                ? {
                    extension: {
                        forceUseFallback: true,
                        fallback: getPromiseRaw(window, "everwallet") ||
                            (function () { return Promise.reject("everwallet fallback error"); }),
                    },
                    standalone: {}, // ?
                }
                : {},
            oxychatwallet: window
                ? {
                    extension: {
                        forceUseFallback: true,
                        fallback: getPromiseRaw(window, "oxychatwallet") ||
                            (function () { return Promise.reject("oxychatwallet fallback error"); }),
                    },
                    standalone: {}, // ?
                }
                : {},
        };
        this.checkNetworkId = options.checkNetworkId;
        this.checkNetworkName = options.checkNetworkName;
        this.providerOptions = options.providersOptions;
        // TODO можно будет задать order для списка
        this.providers = (Object.keys(allProviders.connectors).reverse() || [])
            .filter(function (id) { var _a; return (_a = _this.providerOptions) === null || _a === void 0 ? void 0 : _a[id]; })
            .map(function (id) {
            var _a, _b;
            var providerInfo = 
            // @ts-ignore
            ((_a = allProviders.providers) === null || _a === void 0 ? void 0 : _a[id]) || undefined;
            var _c = (_b = _this.providerOptions) === null || _b === void 0 ? void 0 : _b[id], 
            // wallet,
            links = _c.links, walletWaysToConnect = _c.walletWaysToConnect, defaultWalletWaysToConnect = _c.defaultWalletWaysToConnect;
            var types = walletWaysToConnect === null || walletWaysToConnect === void 0 ? void 0 : walletWaysToConnect.map(function (_a) {
                var type = _a.type;
                return type;
            });
            var ids = walletWaysToConnect === null || walletWaysToConnect === void 0 ? void 0 : walletWaysToConnect.map(function (_a) {
                var id = _a.id;
                return id;
            });
            return {
                id: id,
                // wallet,
                links: links,
                walletWaysToConnect: providerInfo.walletWaysToConnect
                    .filter(function (walletWayToConnect) {
                    return (!!(defaultWalletWaysToConnect === null || defaultWalletWaysToConnect === void 0 ? void 0 : defaultWalletWaysToConnect.includes(walletWayToConnect.type)) &&
                        (!(types === null || types === void 0 ? void 0 : types.includes(walletWayToConnect.type)) ||
                            !(ids === null || ids === void 0 ? void 0 : ids.includes(walletWayToConnect.id))));
                })
                    .concat(walletWaysToConnect || [])
                    .map(function (walletWayToConnect) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                    var defaultWay = 
                    // @ts-ignore
                    (_c = (_b = (_a = allProviders === null || allProviders === void 0 ? void 0 : allProviders.providers) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.walletWaysToConnect) === null || _c === void 0 ? void 0 : _c.find(
                    // allProviders?.providers?.venomwallet?.walletWaysToConnect?.find(
                    // @ts-ignore
                    function (_a) {
                        var type = _a.type;
                        return type === walletWayToConnect.type;
                    });
                    var isCurrentDevise = checkIsCurrentBrowser(defaultWay.options.isCurrentBrowser);
                    var userOptions = walletWayToConnect.packageOptions;
                    var defaultOptions = (_d = defaultPackageOptions[id]) === null || _d === void 0 ? void 0 : _d[walletWayToConnect.type];
                    var packageOptions = isCurrentDevise.isCurrentBrowser
                        ? userOptions || defaultOptions || {}
                        : {};
                    var userOptionsStandalone = walletWayToConnect.packageOptionsStandalone;
                    var defaultOptionsStandalone = (_e = defaultPackageOptions[id]) === null || _e === void 0 ? void 0 : _e["standalone"];
                    var packageOptionsStandalone = userOptionsStandalone || defaultOptionsStandalone || {};
                    // задаём 1000 как дефолт ид венома
                    packageOptions.checkNetworkId = _this.checkNetworkId;
                    packageOptions.checkNetworkName = _this.checkNetworkName;
                    return __assign(__assign(__assign({}, defaultWay), walletWayToConnect), { connector: walletWayToConnect.connector ||
                            (
                            // @ts-ignore
                            (_h = (_g = (_f = allProviders === null || allProviders === void 0 ? void 0 : allProviders.connectors) === null || _f === void 0 ? void 0 : _f[id]) === null || _g === void 0 ? void 0 : _g[walletWayToConnect.type]) === null || _h === void 0 ? void 0 : _h["connector"]), authConnector: walletWayToConnect.authConnector ||
                            (
                            // @ts-ignore
                            (_l = (_k = (_j = allProviders === null || allProviders === void 0 ? void 0 : allProviders.connectors) === null || _j === void 0 ? void 0 : _j[id]) === null || _k === void 0 ? void 0 : _k[walletWayToConnect.type]) === null || _l === void 0 ? void 0 : _l["authChecker"]), standalone: walletWayToConnect.standalone ||
                            (
                            // @ts-ignore
                            (_p = (_o = (_m = allProviders === null || allProviders === void 0 ? void 0 : allProviders.connectors) === null || _m === void 0 ? void 0 : _m[id]) === null || _o === void 0 ? void 0 : _o[walletWayToConnect.type]) === null || _p === void 0 ? void 0 : _p["standalone"]), packageOptions: packageOptions, packageOptionsStandalone: packageOptionsStandalone, options: __assign(__assign({}, (typeof (defaultWay === null || defaultWay === void 0 ? void 0 : defaultWay.options) === "object"
                            ? defaultWay === null || defaultWay === void 0 ? void 0 : defaultWay.options
                            : {})), walletWayToConnect === null || walletWayToConnect === void 0 ? void 0 : walletWayToConnect.options) });
                })
                    .sort(function (a, b) { return sortingArr.indexOf(a.type) - sortingArr.indexOf(b.type); }),
            };
        });
    }
    Object.defineProperty(ProviderController.prototype, "currentProvider", {
        get: function () {
            return this._currentProvider;
        },
        set: function (cp) {
            var updateVenomModal = function () {
                return window.updateVenomModal({
                    isFullProvider: !!cp,
                });
            };
            (function tryUpdateVenomModal() {
                try {
                    updateVenomModal();
                }
                catch (error) {
                    setTimeout(tryUpdateVenomModal, 100);
                }
            })();
            this._currentProvider = cp;
        },
        enumerable: false,
        configurable: true
    });
    ProviderController.prototype.shouldDisplayProvider = function (id) {
        var provider = this.getProvider(id);
        return provider !== undefined;
    };
    ProviderController.prototype.getProviderOption = function (id, connectorId, key) {
        var _a, _b;
        var walletWaysToConnect = (_b = (_a = this.providers) === null || _a === void 0 ? void 0 : _a.find(function (provider) { return provider.id === id; })) === null || _b === void 0 ? void 0 : _b.walletWaysToConnect;
        var walletWayToConnect = walletWaysToConnect === null || walletWaysToConnect === void 0 ? void 0 : walletWaysToConnect.find(function (walletWayToConnect) { return walletWayToConnect.id === connectorId; });
        // @ts-ignore
        return (walletWayToConnect === null || walletWayToConnect === void 0 ? void 0 : walletWayToConnect[key]) || {};
    };
    ProviderController.prototype.on = function (event, callback) {
        var _this = this;
        this.eventController.on({
            event: event,
            callback: callback,
        });
        return function () {
            return _this.eventController.off({
                event: event,
                callback: callback,
            });
        };
    };
    ProviderController.prototype.off = function (event, callback) {
        this.eventController.off({
            event: event,
            callback: callback,
        });
    };
    return ProviderController;
}());
export { ProviderController };
