import './index.css';
import { Theme } from 'stores/ThemeStore';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'providers/ThemeProvider';
import { BsSun, BsMoon } from 'react-icons/bs';

const ThemeSwitcher = observer(() => {
  const theme = useTheme();

  const checked = theme.current === Theme.Dark;

  const onClick = () => {
    theme.setTheme(theme.current === Theme.Dark ? Theme.Light : Theme.Dark);
  };

  return (
    <div
      className='flex justify-around items-center w-full relative cursor-pointer rounded-[50px] theme_switch_background h-9 shadow-primary'
      onClick={onClick}
    >
      <div
        className={`absolute transition-transform duration-300 left-0 w-1/2 theme_toggle_background h-9 rounded-[50px] ${
          checked ? 'translate-x-full' : ''
        }`}
      />
      <BsSun
        className={`${
          checked ? 'text-secondary-1' : 'text-dark'
        } text-base z-[1]`}
      />
      <BsMoon
        className={`${
          checked ? 'text-dark' : 'text-secondary-1'
        } text-base z-[1]`}
      />
    </div>
  );
});

export default ThemeSwitcher;
