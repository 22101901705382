import SnipaRoutes from 'routes';
import EditBundle from 'modules/bundle/components/modals/EditBundle';
import { observer } from 'mobx-react-lite';
import { useLayoutStore } from 'providers/LayoutStoreProvider';
import { useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useBundleStore } from '../providers/BundleStoreProvider';
import { FaPlus } from 'react-icons/fa';
import Button from 'components/core/button';

const BundleList = observer(() => {
  const bundleStore = useBundleStore();
  const layoutStore = useLayoutStore();
  const navigate = useNavigate();

  const onNewBundleClick = useCallback(() => {
    layoutStore.showContentModal(
      <EditBundle
        isCreate={true}
        onCancel={() => layoutStore.hideContentModal()}
        onConfirm={(value) => {
          const createAndHide = async () => {
            const newBundleId = await bundleStore.createBundle(value.name);
            layoutStore.hideContentModal();
            navigate(`/${SnipaRoutes.bundles.path}/${newBundleId}`);
          };

          createAndHide();
        }}
      />
    );
  }, [bundleStore, layoutStore, navigate]);

  if (!bundleStore.bundles) {
    return null;
  }

  return (
    <div className='flex flex-wrap items-center mb-4 gap-2'>
      {bundleStore.bundles?.map((bundle) => (
        <NavLink
          key={bundle.id}
          to={`/${SnipaRoutes.bundles.path}/${bundle.id}`}
          className={({ isActive }) =>
            `rounded-xl px-2 cursor-pointer font-bold text-sm ${
              isActive
                ? 'bg-successBg-1 text-dark'
                : 'bg-primaryBg-2 text-info-1'
            }`
          }
        >
          {bundle.name}
        </NavLink>
      ))}
      <Button
        variant='secondary'
        className='flex items-center justify-between !px-2 !py-0'
        onClick={onNewBundleClick}
      >
        <FaPlus className='mx-1 my-1 text-xs' />
        <span className='hidden md:inline font-bold'>New bundle</span>
      </Button>
    </div>
  );
});

export default BundleList;
