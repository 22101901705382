import './index.css';

type CardProps = {
  className?: string;
  children?: any;
};

const Card = ({ className, children }: CardProps) => {
  return (
    <div
      className={
        'w-full shadow-primary card_container rounded-2xl p-4 ' + className
      }
    >
      {children}
    </div>
  );
};

export default Card;
