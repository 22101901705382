import { BundleExchangeKeyDTO } from 'api';

export class BundleExchangeKeyModel {
  id: string;
  exchange: string;
  memo: string;
  name: string;

  constructor(dto: BundleExchangeKeyDTO) {
    this.id = dto.id;
    this.exchange = dto.exchange;
    this.memo = dto.memo;
    this.name = dto.name;
  }
}
