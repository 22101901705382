import { Api, BundleDTO } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';

export class BundleStore {
  constructor(private api: Api<unknown>) {
    makeAutoObservable(this);
  }

  initialized = false;
  bundles?: BundleDTO[];

  async fetchBundleList() {
    const result = await this.api.bundle.bundleList();

    runInAction(() => {
      this.bundles = result.data;
    });
  }

  async createBundle(bundleName: string): Promise<string> {
    const result = await this.api.bundle.addBundle({ name: bundleName });
    await this.fetchBundleList();

    return result.data.id;
  }

  async editBundle(id: string, bundleName: string) {
    await this.api.bundle.editBundle({ id: id, newName: bundleName });
    await this.fetchBundleList();
  }

  async deleteBundle(id: string) {
    await this.api.bundle.deleteBundle({ id: id });
    await this.fetchBundleList();
  }

  async addAccountToBundle(bundleId: string, address: string, memo?: string) {
    await this.api.bundle.addAccountToBundle({
      bundleId: bundleId,
      accountAddress: address,
      accountMemo: memo ?? null,
    });
  }

  async editAccountInBundle(
    bundleId: string,
    oldAddress: string,
    newAddress: string,
    memo?: string
  ) {
    await this.api.bundle.editAccountInBundle({
      bundleId: bundleId,
      oldAccountAddress: oldAddress,
      newAccountAddress: newAddress,
      accountMemo: memo ?? null,
    });
  }

  async removeAccountFromBundle(bundleId: string, address: string) {
    await this.api.bundle.removeAccountFromBundle({
      bundleId: bundleId,
      accountAddress: address,
      accountMemo: null,
    });
  }

  async addExchangeKeyToBundle(bundleId: string, exchangeKeyId: string) {
    await this.api.bundle.addExchangeKeyToBundle({
      bundleId: bundleId,
      exchangeKeyId: exchangeKeyId,
    });
  }

  async editExchangeKeyInBundle(
    bundleId: string,
    oldExchangeKeyId: string,
    newExchangeKeyId: string
  ) {
    await this.api.bundle.editExchangeKeyInBundle({
      bundleId: bundleId,
      oldExchangeKeyId: oldExchangeKeyId,
      newExchangeKeyId: newExchangeKeyId,
    });
  }

  async removeExchangeKeyFromBundle(bundleId: string, exchangeKeyId: string) {
    await this.api.bundle.removeExchangeKeyFromBundle({
      bundleId: bundleId,
      exchangeKeyId: exchangeKeyId,
    });
  }

  async init() {
    await this.fetchBundleList();

    runInAction(() => {
      this.initialized = true;
    });
  }
}
