import {
  Blockchain,
  CounterpartyType,
  EvmNetwork,
  HistoryItem,
  HistoryItemType,
} from 'api';
import SnipaRoutes from 'routes';
import { capitalizeString } from 'utils/strings';
import { SupplyTokenModel } from './SupplyTokenModel';

type ProjectInfo = {
  name?: string | undefined;
  logo?: string | undefined;
};

const itemTypeToString = (type: HistoryItemType) => {
  if (!type || type.length === 0) {
    return '';
  }

  const str = type.replace(/([A-Z])/g, ' $1').trim();

  return capitalizeString(str);
};

const blockchainToString = (blockchain: Blockchain) => {
  if (blockchain.evm === EvmNetwork.BnbChain) {
    return 'BNB Chain';
  }

  if (blockchain.evm) {
    return capitalizeString(blockchain.evm);
  }

  return capitalizeString(blockchain.toString());
};

export class TransactionHistoryModel {
  timestamp: number;
  transactionHash: string;
  userAddress: string;
  transactionUrl?: string;

  operation: string;

  project?: ProjectInfo | undefined;

  tokens: SupplyTokenModel[];
  fee?: SupplyTokenModel[] | undefined;

  fromBlockhain?: string | undefined;
  toBlockchain?: string | undefined;

  counterParty?: {
    isSender: boolean;
    link: string;
    address: string;
  };

  constructor(dto: HistoryItem, project?: ProjectInfo | undefined) {
    this.transactionHash = dto.transactionHash;
    this.userAddress = dto.userAddress;
    this.timestamp = dto.timestamp;
    this.transactionUrl = dto.link;

    this.tokens = dto.tokens
      .map((x) => new SupplyTokenModel(x))
      .filter((x) => !x.amount.isZero());

    this.fee = dto.fee?.map((x) => new SupplyTokenModel(x));

    this.project = project;

    this.operation = itemTypeToString(dto.itemType);

    if (dto.itemType === HistoryItemType.BridgeTransfer) {
      this.fromBlockhain = blockchainToString(dto.fromBlockchain);
      this.toBlockchain = blockchainToString(dto.toBlockchain);
    }

    this.counterParty = dto.counterparty
      ? {
          isSender:
            dto.counterparty.counterpartyType === CounterpartyType.Sender,
          link: `/${SnipaRoutes.profile.path}/${dto.counterparty.address}`,
          address: dto.counterparty.address,
        }
      : undefined;
  }
}
