import Tokens from 'components/core/tokens';
import TokenValues from 'components/core/token-values';
import { LaunchPoolModel } from 'models/portfolio/LaunchPoolModel';
import { PeriodVestingModel } from 'models/portfolio/PeriodVestingModel';
import { useMemo, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { bigNumberToStr, unixDateToStr } from 'utils/strings';
import ProjectCardTitle from '../project/ProjectCardTitle';
import Card from 'components/core/card';

const itemsToTake = 3;

const VestingSchedule = ({ data }: { data: PeriodVestingModel[] }) => {
  const [cursor, setCursor] = useState(0);

  const itemToDisplay = useMemo(
    () => data.slice(cursor, cursor + itemsToTake),
    [cursor, data]
  );

  return (
    <div className='flex items-center justify-center gap-x-2'>
      <div className='flex-shrink-0 w-5'>
        {cursor > 0 && (
          <BsChevronLeft
            className='text-lg'
            onClick={() => setCursor((prev) => prev - 1)}
          />
        )}
      </div>
      <div className='flex gap-x-3'>
        {itemToDisplay.map((x) => (
          <div key={x.unfreezeTime} className='flex flex-col text-sm'>
            <span>{bigNumberToStr(x.percent, 2)}%</span>
            <span className='text-secondary-1'>
              {unixDateToStr(x.unfreezeTime, 'DD.MM.YYYY')}
            </span>
          </div>
        ))}
      </div>
      <div className='flex-shrink-0 w-5'>
        {cursor + itemsToTake < data.length && (
          <BsChevronRight
            className='text-lg'
            onClick={() => setCursor((prev) => prev + 1)}
          />
        )}
      </div>
    </div>
  );
};

const LaunchPoolCards = ({ data }: { data: LaunchPoolModel[] }) => {
  return (
    <div className='flex flex-col gap-3'>
      {data.map((pool) => (
        <Card key={pool.address}>
          <ProjectCardTitle title='Launch Pool' />
          <div className='grid grid-cols-2 gap-2'>
            <Tokens tokens={[pool.allocationVesting.total]} />

            <div>
              <span className='text-secondary-1'>Vested</span>
              <TokenValues tokens={[pool.allocationVesting.total]} />
            </div>

            <div>
              <span className='text-secondary-1'>Deposited</span>
              <TokenValues tokens={[pool.depositedToken]} />
            </div>

            <div>
              <span className='text-secondary-1'>USD Value</span>
              <div className='text-sm font-bold'>
                ${bigNumberToStr(pool.totalUsdValue, 0)}
              </div>
            </div>

            {pool.allocationVesting.vesting.length > 0 && (
              <div className='col-span-2 mt-4'>
                <VestingSchedule data={pool.allocationVesting.vesting} />
              </div>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default LaunchPoolCards;
