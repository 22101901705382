import Card from 'components/core/card';
import { TransactionHistoryModel } from 'models/portfolio/TransactionHistoryModel';
import { NavLink } from 'react-router-dom';
import SnipaRoutes from 'routes';
import { cutString, unixDateToStr } from 'utils/strings';
import TransactionHistoryToken from './TransactionHistoryToken';

const TransactionHistoryCards = ({
  data,
  showUserAddress,
}: {
  data: TransactionHistoryModel[];
  showUserAddress: boolean;
}) => {
  return (
    <div className='flex flex-col gap-3'>
      {data.map((item, index) => (
        <Card key={index}>
          <div className='grid grid-cols-2 gap-2'>
            <div>
              <div className='flex flex-col gap-1 text-sm'>
                {item.project && (
                  <div className='flex gap-1'>
                    <img src={item.project.logo} className='w-5 h-5' alt='' />
                    <span>{item.project.name}</span>
                  </div>
                )}
                <span className='font-bold text-sm'>{item.operation}</span>

                {showUserAddress && (
                  <span className='flex gap-1 text-secondary-1'>
                    <span className='text-secondary-1'>By:</span>
                    <NavLink
                      to={`/${SnipaRoutes.profile.path}/${item.userAddress}`}
                      className='underline'
                    >
                      {cutString(item.userAddress, 10, 4)}
                    </NavLink>
                  </span>
                )}

                {item.fromBlockhain && (
                  <span className='flex gap-1'>
                    <span className='text-secondary-1'>From:</span>
                    <span>{item.fromBlockhain}</span>
                  </span>
                )}

                {item.toBlockchain && (
                  <span className='flex gap-1'>
                    <span className='text-secondary-1'>To:</span>
                    <span>{item.toBlockchain}</span>
                  </span>
                )}

                {item.counterParty && (
                  <span className='flex gap-1 text-secondary-1'>
                    <span>
                      {item.counterParty.isSender === true ? 'From' : 'To'}:
                    </span>
                    <a
                      className='underline'
                      href={item.counterParty.link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {cutString(item.counterParty.address, 8, 4)}
                    </a>
                  </span>
                )}
              </div>
            </div>
            <div>
              <div className='flex flex-col text-sm'>
                <span className='font-bold'>
                  {unixDateToStr(item.timestamp, 'DD.MM.YYYY HH:mm')}
                </span>
                <a
                  href={item.transactionUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-secondary-1 underline'
                >
                  <span>{cutString(item.transactionHash, 10, 4)}</span>
                </a>
              </div>
            </div>
            <div className='col-span-2'>
              <div className='flex flex-col gap-1'>
                {item.tokens.map((x, i) => (
                  <TransactionHistoryToken
                    key={i}
                    symbol={x.symbol}
                    rootAddress={x.rootAddress}
                    amount={x.amount}
                    usdValue={x.usdValue}
                    isNative={x.isNative}
                    network={x.network}
                  />
                ))}
              </div>
            </div>
            {item.fee && (
              <div className='col-span-2'>
                <div className='flex flex-col gap-1'>
                  <span className='text-sm text-secondary-1'>Fee</span>
                  {item.fee.map((x, i) => (
                    <TransactionHistoryToken
                      key={i}
                      symbol={x.symbol}
                      rootAddress={x.rootAddress}
                      amount={x.amount}
                      usdValue={x.usdValue}
                      isNative={x.isNative}
                      network={x.network}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default TransactionHistoryCards;
