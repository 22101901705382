import Portfolio from 'components/portfolio';
import { observer } from 'mobx-react-lite';
import { useBundlePortfolioStore } from '../providers/BundlePortfolioStoreProvider';

const BundlePortfolio = observer(() => {
  const bundlePortfolioStore = useBundlePortfolioStore();

  if (!bundlePortfolioStore.portfolio) {
    return null;
  }

  return <Portfolio networks={bundlePortfolioStore.portfolio} />;
});

export default BundlePortfolio;
