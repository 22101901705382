import { makeAutoObservable, runInAction } from 'mobx';

const themeStorageKey = 'snipa.finance_theme';

export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

export class ThemeStore {
  constructor() {
    makeAutoObservable(this);
  }

  initialized = false;
  current?: Theme;

  async init() {
    const initTheme = this.getThemeFromStorage();

    this.setTheme(initTheme ?? Theme.Dark);
  }

  setTheme(value: Theme) {
    if (this.current === value) {
      return;
    }

    document.body.classList.remove(`theme-${this.current}`);
    document.body.classList.add(`theme-${value}`);

    localStorage.setItem(themeStorageKey, value);

    runInAction(() => {
      this.current = value;

      if (!this.initialized) {
        this.initialized = true;
      }
    });
  }

  private getThemeFromStorage() {
    const themeStr = localStorage.getItem(themeStorageKey);

    if (!themeStr) {
      return undefined;
    }

    return themeStr as Theme;
  }
}
