import { ExchangeKeyBalanceDTO } from 'api';
import BigNumber from 'bignumber.js';
import { groupBy, map } from 'lodash';
import { ExchangeTokenBalanceModel } from './ExchangeTokenBalanceModel';

export class ExchangeWalletModel {
  tokens: ExchangeTokenBalanceModel[];
  totalUsdValue: BigNumber;

  constructor(balances?: ExchangeKeyBalanceDTO[]) {
    this.tokens = balances?.map((x) => new ExchangeTokenBalanceModel(x)) ?? [];
    this.totalUsdValue = this.tokens
      .map((x) => x.total)
      .reduce((x, y) => x.plus(y), new BigNumber(0));
  }

  combine(wallet: ExchangeWalletModel) {
    const result = new ExchangeWalletModel();

    const tokenGroups = groupBy(
      this.tokens.concat(wallet.tokens),
      (x) => x.symbol
    );

    result.tokens = map(tokenGroups, (tokens) =>
      tokens.reduce((x, y) => x.combine(y))
    );

    result.totalUsdValue = result.tokens
      .map((x) => x.total)
      .reduce((x, y) => x.plus(y), new BigNumber(0));

    return result;
  }
}
