import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { Api } from 'api';
import { VenomConnect } from 'venom-connect';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { ProviderRpcClient } from 'everscale-inpage-provider';
import { Buffer as BufferBrowser } from 'buffer';
import { ThemeStore } from './ThemeStore';
import { loginEnabled } from 'utils/beta';

type TokenData = {
  value: string;
  exp: number;
};

type EverAccount = {
  address: string;
  publicKey: string;
  walletType: string;
  networkId: number;
};

const jwtExpirationSlippageSec = 5;
const jwtStorageKey = 'snipa.finance_token';

const getLoginMsg = (address: string, timestamp: number) =>
  `I want to login at snipa.finance with address ${address} at ${timestamp}`;

export class AuthStore {
  constructor(private api: Api<unknown>, private theme: ThemeStore) {
    makeAutoObservable(this);

    this.venomConnect = this.initVenomConnect();

    reaction(
      () => this.theme.current,
      () => this.venomConnect.updateTheme(this.theme.current!)
    );
  }

  initialized: boolean = false;
  extensionInstalled: boolean = false;
  account?: EverAccount;

  private venomConnect: VenomConnect;
  private walletProvider?: ProviderRpcClient;
  private jwtToken?: TokenData;

  get loggedIn() {
    if (loginEnabled) {
      return !!this.jwtToken && !!this.account;
    }

    return !!this.account;
  }

  get provider() {
    return this.walletProvider;
  }

  async init() {
    try {
      const walletProvider = await this.venomConnect.checkAuth();

      if (walletProvider) {
        runInAction(() => {
          this.walletProvider = walletProvider;
        });
      }

      this.venomConnect.on('connect', (provider) => {
        if (loginEnabled) {
          this.authUserAfterConnect(provider);
          return;
        }

        this.updateAccountState();
      });

      await this.updateAccountState();

      const token = localStorage.getItem(jwtStorageKey);

      this.setJwtToken(token);

      runInAction(() => {
        this.extensionInstalled = true;
        this.initialized = true;
      });
    } catch {
      runInAction(() => {
        this.extensionInstalled = false;
        this.initialized = true;
      });
    }
  }

  async login() {
    try {
      await this.venomConnect.connect();
    } catch {
      await this.logout();
    }
  }

  private async authUserAfterConnect(provider: ProviderRpcClient) {
    try {
      runInAction(() => {
        this.walletProvider = provider;
      });

      await this.updateAccountState();

      if (!this.account) {
        return;
      }

      const timestamp = moment().unix();
      const msg = getLoginMsg(this.account.address, timestamp);
      const signedData = await this.signMessage(msg);

      const result = await this.api.auth.login({
        address: this.account.address,
        publicKey: this.account.publicKey,
        walletType: this.account.walletType,
        timestamp: timestamp,
        signature: signedData?.signature ?? '',
        withSignatureId: signedData?.withSignatureId ?? null,
      });

      this.setJwtToken(result.data);
    } catch (e) {
      await this.logout();
    }
  }

  async logout() {
    await this.walletProvider?.disconnect();
    await this.updateAccountState();

    this.clearJwtToken();
  }

  private setJwtToken(token: string | null | undefined) {
    if (!token || !this.account) {
      return;
    }

    const decodedToken = jwt_decode<{ exp: number }>(token);

    if (this.jwtTokenExpired(decodedToken.exp)) {
      return;
    }

    localStorage.setItem(jwtStorageKey, token);

    runInAction(() => {
      this.jwtToken = {
        value: token,
        exp: decodedToken.exp,
      };
      this.refreshHttpInterceptors();
    });
  }

  private clearJwtToken() {
    localStorage.removeItem(jwtStorageKey);

    runInAction(() => {
      this.jwtToken = undefined;
      this.refreshHttpInterceptors();
    });
  }

  private jwtTokenExpired(exp: number) {
    return moment().unix() >= exp - jwtExpirationSlippageSec;
  }

  private async signMessage(message: string) {
    if (!this.account || !this.walletProvider) {
      return null;
    }

    const base64Msg = BufferBrowser.from(message, 'utf-8').toString('base64');
    const publicKey = this.account.publicKey;
    // 'withSignatureId: networkId' works for VenomWallet extension, EverWallet extension and VenomWallet mobile
    // EverWallet mobile does not support withSignatureId provided hence 'withSignatureId: false' required
    const withSignatureId = [1000, 1010].includes(this.account.networkId)
      ? this.account.networkId
      : null;

    const signedData = await this.walletProvider.signData({
      data: base64Msg,
      publicKey: publicKey,
      withSignatureId: withSignatureId ?? false,
    });

    return {
      signature: signedData.signature,
      withSignatureId: withSignatureId,
    };
  }

  private async updateAccountState() {
    if (!this.walletProvider) return;

    const {
      permissions: { accountInteraction },
      networkId,
    } = await this.walletProvider.getProviderState();

    runInAction(() => {
      if (!accountInteraction) {
        this.account = undefined;
        return;
      }

      this.account = {
        address: accountInteraction.address?.toString(),
        publicKey: accountInteraction.publicKey,
        walletType: accountInteraction.contractType,
        networkId: networkId,
      };
    });
  }

  private requestInterceptorId = 0;
  private responseInterceptorId = 0;

  private refreshHttpInterceptors() {
    this.api.instance.interceptors.request.eject(this.requestInterceptorId);
    this.api.instance.interceptors.response.eject(this.responseInterceptorId);

    const token = this.jwtToken;

    if (!token) {
      return;
    }

    this.requestInterceptorId = this.api.instance.interceptors.request.use(
      async (cfg) => {
        if (this.jwtTokenExpired(token.exp)) {
          this.clearJwtToken();
          return cfg;
        }

        if (cfg.headers) {
          cfg.headers['Authorization'] = `Bearer ${token.value}`;
        }

        return cfg;
      },
      (err) => Promise.reject(err)
    );

    this.responseInterceptorId = this.api.instance.interceptors.response.use(
      (cfg) => cfg,
      async (err) => {
        if (err.response?.status === 401 && this.loggedIn) {
          this.clearJwtToken();
        }

        return Promise.reject(err);
      }
    );
  }

  private initVenomConnect = () => {
    return new VenomConnect({
      theme: this.theme.current,
      checkNetworkId: [1000, 1010, 1, 42],
      providersOptions: {
        venomwallet: {
          walletWaysToConnect: [
            {
              package: ProviderRpcClient,
              packageOptions: {
                fallback:
                  VenomConnect.getPromise('venomwallet', 'extension') ||
                  (() => Promise.reject()),
                forceUseFallback: true,
              },
              id: 'extension',
              type: 'extension',
            },
          ],
          defaultWalletWaysToConnect: ['mobile', 'ios', 'android'],
        },
        // everwallet: {
        //   links: {
        //     qr: null,
        //   },
        //   walletWaysToConnect: [
        //     {
        //       package: ProviderRpcClient,
        //       packageOptions: {
        //         fallback:
        //           VenomConnect.getPromise('everwallet', 'extension') ||
        //           (() => Promise.reject()),
        //         forceUseFallback: true,
        //       },
        //       id: 'extension',
        //       type: 'extension',
        //     },
        //   ],
        //   defaultWalletWaysToConnect: ['mobile', 'ios', 'android'],
        // },
      },
    });
  };
}
