import Card from 'components/core/card';
import Tokens from 'components/core/tokens';
import { SupplyTokenModel } from 'models/portfolio/SupplyTokenModel';
import { bigNumberToStr } from 'utils/strings';
import ProjectCardTitle from '../project/ProjectCardTitle';

const LiquidityCards = ({ data }: { data: SupplyTokenModel[] }) => {
  return (
    <div className='flex flex-col gap-3'>
      {data.map((item, index) => (
        <Card key={index}>
          <ProjectCardTitle title='Liquidity' />

          <div className='grid grid-cols-2 gap-2'>
            <Tokens tokens={[item]} />

            <div>
              <span className='text-secondary-1'>USD Value</span>
              <div className='text-sm font-bold'>
                ${bigNumberToStr(item.usdValue, 0)}
              </div>
            </div>

            <div className='col-span-2'>
              <span className='text-secondary-1'>Balance</span>
              <div className='text-sm flex gap-1'>
                <span>
                  {bigNumberToStr(item.amount, 3, { useShortNotation: true })}
                </span>
                <span>{item.symbol}</span>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};
export default LiquidityCards;
