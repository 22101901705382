import { useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext } from 'react';
import { useApi } from 'providers/ApiProvider';
import { ProjectListStore } from '../stores/ProjectListStore';
import { useStaticData } from 'providers/StaticDataStoreProvider';

const projectStoreListContext = createContext<ProjectListStore>(
  {} as ProjectListStore
);

export const ProvideProjectListStore = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const api = useApi();
  const staticData = useStaticData();
  const store = useLocalObservable(() => new ProjectListStore(api, staticData));

  return (
    <projectStoreListContext.Provider value={store}>
      {children}
    </projectStoreListContext.Provider>
  );
};

export const useProjectListStore = () => {
  return useContext(projectStoreListContext);
};
