import Pagination from 'components/core/pagination';
import UsersListTable from './UsersListTable';
import { observer } from 'mobx-react-lite';
import { useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { strToPageNumber } from 'utils/pagination';
import { useProjectStore } from '../providers/ProjectStoreProvider';

const ProjectUsers = observer(() => {
  const projectStore = useProjectStore();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = useMemo(() => {
    const val = searchParams.get('up');

    let page = strToPageNumber(val);

    if (projectStore.users?.maxPage && page > projectStore.users?.maxPage) {
      page = projectStore.users.maxPage;
    }

    return page;
  }, [projectStore.users?.maxPage, searchParams]);

  useEffect(() => {
    if (!projectStore.project) {
      return;
    }

    projectStore.fetchUsers(currentPage);
  }, [currentPage, projectStore, projectStore.project]);

  if (!projectStore.users || projectStore.users.items.length === 0) {
    return null;
  }

  return (
    <div className='flex flex-col gap-6'>
      <UsersListTable users={projectStore.users.items} />

      <Pagination
        currentPage={currentPage}
        maxPage={projectStore.users.maxPage}
        onChange={(newPage) => setSearchParams({ up: newPage.toString() })}
      />
    </div>
  );
});

export default ProjectUsers;
