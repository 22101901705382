import BundleList from './BundleList';
import Container from 'components/core/container';

const BundleNavbar = () => {
  return (
    <div className='bg-navbar-mainBg'>
      <Container className='pt-6'>
        <BundleList />
      </Container>
    </div>
  );
};

export default BundleNavbar;
