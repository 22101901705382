import SnipaRoutes from 'routes';
import Navbar from './layout/navbar';
import Footer from './layout/footer';
import Sidebar from './layout/sidebar';
import NotFound from './layout/NotFound';
import BundlePage from 'modules/bundle';
import LandingPage from 'modules/landing';
import MobilePage from 'modules/mobile';
import ProfilePage from 'modules/profile';
import ProjectPage from 'modules/project';
import ExchangeKeyPage from 'modules/exchange-key';
import MaintenancePage from 'modules/maintenance';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ProvideApi } from 'providers/ApiProvider';
import { ProvideAuth } from 'providers/AuthProvider';
import { ProvideLayout } from 'providers/LayoutStoreProvider';
import { ProvideNotification } from 'providers/NotificationProvider';
import { ProvideStaticData } from 'providers/StaticDataStoreProvider';
import { ProvideTheme } from 'providers/ThemeProvider';
import { loginEnabled, showExchangeKeys } from 'utils/beta';

const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE === 'true';

const AppProviders = ({ children }: { children: JSX.Element }) => {
  return (
    <ProvideTheme>
      <ProvideNotification>
        <ProvideLayout>
          <ProvideApi>
            <ProvideAuth>
              <ProvideStaticData>{children}</ProvideStaticData>
            </ProvideAuth>
          </ProvideApi>
        </ProvideLayout>
      </ProvideNotification>
    </ProvideTheme>
  );
};

const App = () => {
  if (MAINTENANCE_MODE) {
    return <MaintenancePage />;
  }

  return (
    <AppProviders>
      <Router>
        <div className='flex w-screen'>
          <Sidebar />
          <div className='flex flex-col min-h-screen max-h-screen overflow-auto w-full'>
            <Navbar />
            <Routes>
              <Route index element={<LandingPage />} />
              <Route
                path={`${SnipaRoutes.profile.path}/*`}
                element={<ProfilePage />}
              />

              {loginEnabled && (
                <Route
                  path={`${SnipaRoutes.bundles.path}/*`}
                  element={<BundlePage />}
                />
              )}

              <Route
                path={`${SnipaRoutes.projects.path}/*`}
                element={<ProjectPage />}
              />

              {showExchangeKeys && (
                <Route
                  path={`${SnipaRoutes.exchangeKeys.path}/*`}
                  element={<ExchangeKeyPage />}
                />
              )}

              <Route
                path={`${SnipaRoutes.mobile.path}/*`}
                element={<MobilePage />}
              />
              <Route path='*' element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </Router>
    </AppProviders>
  );
};

export default App;
