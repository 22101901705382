import { useMemo, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

interface Tab {
  id: string;
  text: string;
  navLink?: string;
}

const ActiveUnderline = () => {
  return (
    <div className='absolute left-0 -bottom-[2px] h-[3px] w-full bg-success-1' />
  );
};

const itemsToTake = 3;

const TabNavLink = ({ tab }: { tab: Tab }) => {
  return (
    <NavLink
      to={tab.navLink ?? ''}
      className='relative no-underline pb-1 px-4'
      end
    >
      {({ isActive }) => (
        <span
          className={
            isActive ? 'text-highlight font-semibold' : 'text-secondary-1'
          }
        >
          {tab.text}
          {isActive === true && <ActiveUnderline />}
        </span>
      )}
    </NavLink>
  );
};

const TabButton = ({
  tab,
  activeTabId,
  onTabChanged,
}: {
  tab: Tab;
  activeTabId?: string;
  onTabChanged?: (tab: Tab) => void;
}) => {
  return (
    <span
      className={`relative pb-1 px-4 cursor-pointer ${
        tab.id === activeTabId
          ? 'text-highlight font-semibold'
          : 'text-secondary-1'
      }`}
      onClick={() => {
        if (tab.id === activeTabId) {
          return;
        }

        onTabChanged?.(tab);
      }}
    >
      {tab.text}
      {tab.id === activeTabId && <ActiveUnderline />}
    </span>
  );
};

const TabControl = ({
  tab,
  activeTabId,
  onTabChanged,
}: {
  tab: Tab;
  activeTabId?: string;
  onTabChanged?: (tab: Tab) => void;
}) => {
  return tab.navLink != null ? (
    <TabNavLink key={tab.id} tab={tab} />
  ) : (
    <TabButton
      key={tab.id}
      tab={tab}
      activeTabId={activeTabId}
      onTabChanged={onTabChanged}
    />
  );
};

const TabsSelect = ({
  data,
  activeTabId,
  onTabChanged,
}: {
  data: Tab[];
  activeTabId?: string;
  onTabChanged?: (tab: Tab) => void;
}) => {
  const [cursor, setCursor] = useState(0);

  const itemToDisplay = useMemo(
    () => data.slice(cursor, cursor + itemsToTake),
    [cursor, data]
  );

  return (
    <>
      <div className='flex items-center justify-center gap-x-2 sm:hidden'>
        <div className='flex-shrink-0'>
          {cursor > 0 && (
            <BsChevronLeft
              className='text-lg'
              onClick={() => setCursor((prev) => prev - 1)}
            />
          )}
        </div>
        <div className='flex items-center w-full'>
          {itemToDisplay.map((x, i) => (
            <TabControl
              key={i}
              tab={x}
              activeTabId={activeTabId}
              onTabChanged={onTabChanged}
            />
          ))}
        </div>
        <div className='flex-shrink-0'>
          {cursor + itemsToTake < data.length && (
            <BsChevronRight
              className='text-lg'
              onClick={() => setCursor((prev) => prev + 1)}
            />
          )}
        </div>
      </div>
      <div className='hidden sm:flex sm:items-center sm:w-full'>
        {data.map((x, i) => (
          <TabControl
            key={i}
            tab={x}
            activeTabId={activeTabId}
            onTabChanged={onTabChanged}
          />
        ))}
      </div>
    </>
  );
};

export default TabsSelect;
