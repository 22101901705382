import Button from 'components/core/button';

const Confirmation = ({
  header,
  text,
  onConfirm,
  onCancel,
}: {
  header?: string;
  text?: string;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <div className='text-center'>
      {header && (
        <h3 className='mx-4 text-base text-center font-bold'>{header}</h3>
      )}

      {text && <h5 className='px-4 my-4 text-sm'>{text}</h5>}

      <div className='flex justify-between mt-8'>
        <Button variant='secondary' className='w-28' onClick={onCancel}>
          <span>Cancel</span>
        </Button>

        <Button variant='primary' className='w-28' onClick={onConfirm}>
          <span>Yes</span>
        </Button>
      </div>
    </div>
  );
};

export default Confirmation;
