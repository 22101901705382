import { useCallback } from 'react';
import * as yup from 'yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import InputField from 'components/core/input-field';
import Button from 'components/core/button';

type FormFields = {
  address: string;
  memo?: string;
};

export type OnEditConfirmProps = {
  newAddress: string;
  oldAddress?: string;
  memo?: string;
};

const BundleFormSchema = yup.object({
  address: yup
    .string()
    // .matches(/^0:+[\dA-Za-z]{64}$/, {
    //   excludeEmptyString: true,
    //   message: 'Incorrect format',
    // })
    .max(70, 'Value is too long')
    .nullable()
    .required('Field is required'),
  memo: yup.string().max(30, 'Value is too long'),
});

const EditAccount = ({
  initValue,
  onConfirm,
  onCancel,
}: {
  initValue?: { address: string; memo?: string };
  onConfirm: (props: OnEditConfirmProps) => void;
  onCancel: () => void;
}) => {
  const { handleSubmit, control } = useForm<FormFields>({
    resolver: yupResolver(BundleFormSchema),
    mode: 'onChange',
    defaultValues: {
      address: initValue?.address,
      memo: initValue?.memo,
    },
  });

  const applyForm = useCallback<SubmitHandler<FormFields>>(
    (data) => {
      onConfirm({
        newAddress: data.address,
        oldAddress: initValue?.address,
        memo: data.memo,
      });
    },
    [onConfirm, initValue]
  );

  return (
    <form className='flex flex-col' onSubmit={handleSubmit(applyForm)}>
      <div className='text-base'>Add address to bundle</div>
      <div className='text-secondary-1 text-sm my-2'>
        Enter an address for the bundle
      </div>
      <Controller
        control={control}
        name='address'
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <InputField
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            error={error?.message}
          />
        )}
      ></Controller>
      <div className='text-secondary-1 text-sm my-2'>
        Description (optional)
      </div>
      <Controller
        control={control}
        name='memo'
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <InputField
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            error={error?.message}
          />
        )}
      ></Controller>
      <div className='flex justify-between mt-6 flex-row-reverse'>
        <Button variant='primary' className='w-28' type='submit'>
          <span>Save</span>
        </Button>

        <Button
          variant='secondary'
          className='w-28'
          type='button'
          onClick={onCancel}
        >
          <span>Cancel</span>
        </Button>
      </div>
    </form>
  );
};

export default EditAccount;
