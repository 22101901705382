import { useMdMediaQuery } from 'utils/responsiveness';
import { ReactComponent as HideSvg } from 'assets/svg/hide.svg';
import { ReactComponent as ShowSvg } from 'assets/svg/show.svg';
import Table from 'components/core/table';
import Tokens from 'components/core/tokens';
import TokenValues from 'components/core/token-values';
import { LaunchPoolModel } from 'models/portfolio/LaunchPoolModel';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { bigNumberToStr } from 'utils/strings';
import VestingScheduleTable from './vesting-schedule-table';
import LaunchPoolCards from './LaunchPoolCards';

type LaunchPoolColumn = LaunchPoolModel & {
  allocationSchedule: boolean;
  hideColumn: boolean;
};

const LaunchPoolTable = ({ poolInfo }: { poolInfo: LaunchPoolModel[] }) => {
  const columns = useMemo<Column<LaunchPoolColumn>[]>(
    () => [
      {
        Header: 'Pool',
        accessor: 'address',
        Cell: ({ row }) => (
          <Tokens tokens={[row.original.allocationVesting.total]} />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Deposited',
        accessor: 'depositedToken',
        Cell: ({ row }) => (
          <TokenValues tokens={[row.original.depositedToken]} />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Vested',
        accessor: 'allocationVesting',
        Cell: ({ row }) => (
          <TokenValues tokens={[row.original.allocationVesting.total]} />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Vesting schedule',
        accessor: 'allocationSchedule',
        Cell: ({ row }) =>
          row.original.allocationVesting.vesting.length > 0 ? (
            <div className='flex items-center gap-x-1'>
              <span>{row.isExpanded ? 'Hide' : 'Show'}</span>
              {row.isExpanded ? (
                <HideSvg
                  className='w-4 h-4'
                  {...row.getToggleRowExpandedProps()}
                  title=''
                />
              ) : (
                <ShowSvg
                  className='w-4 h-4'
                  {...row.getToggleRowExpandedProps()}
                  title=''
                />
              )}
            </div>
          ) : (
            <span className='text-secondary-1'>No vesting</span>
          ),
        disableSortBy: true,
      },
      {
        Header: 'USD Value',
        accessor: 'totalUsdValue',
        Cell: ({ row }) => (
          <span className='font-bold'>
            ${bigNumberToStr(row.original.totalUsdValue, 0)}
          </span>
        ),
        sortType: (rowA, rowB) => {
          return rowA.original.totalUsdValue.comparedTo(
            rowB.original.totalUsdValue
          );
        },
        className: 'w-48',
      },
    ],
    []
  );

  const defaultSort = useMemo(() => ({ id: 'totalUsdValue', desc: true }), []);
  const isDesktop = useMdMediaQuery();

  return isDesktop ? (
    <Table
      columns={columns}
      data={poolInfo}
      title='Launch Pool'
      defaultSortBy={defaultSort}
      subComponent={(row) => (
        <VestingScheduleTable data={row.allocationVesting.vesting} />
      )}
    />
  ) : (
    <LaunchPoolCards data={poolInfo} />
  );
};

export default LaunchPoolTable;
