import Button from 'components/core/button';
import Container from 'components/core/container';
import Card from 'components/core/card';
import TransactionHistoryTable from './table/TransactionHistoryTable';
import { useMemo } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { TransactionHistoryModel } from 'models/portfolio/TransactionHistoryModel';

type TransactionHistoryProps = {
  items?: TransactionHistoryModel[];
  canLoadMore: boolean;
  loading: boolean;
  showUserAddress?: boolean;
  fetchMore: () => void;
};

const TransactionHistory = ({
  items,
  canLoadMore,
  showUserAddress,
  loading,
  fetchMore,
}: TransactionHistoryProps) => {
  const tableComponent = useMemo(
    () =>
      items ? (
        <TransactionHistoryTable
          data={items}
          showUserAddress={showUserAddress ?? false}
        />
      ) : null,
    [items, showUserAddress]
  );

  return (
    <Container className='flex flex-col py-6 lg:py-12 gap-6'>
      {(!items || items.length === 0) && (
        <Card className='text-center py-8 text-secondary-1'>
          {!items ? (
            <span>Fetching transaction history...</span>
          ) : (
            <span>No transactions were found.</span>
          )}
        </Card>
      )}

      {items && items.length > 0 && tableComponent}

      {items && items.length > 0 && canLoadMore === true && (
        <div className='flex justify-center'>
          <Button
            variant='primary'
            disabled={loading}
            onClick={fetchMore}
            className='flex items-center gap-x-2'
          >
            <FiRefreshCw className={loading ? 'icon-spin' : ''} />
            <span>Load more...</span>
          </Button>
        </div>
      )}
    </Container>
  );
};

export default TransactionHistory;
