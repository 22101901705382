import './index.css';
import React, { useMemo } from 'react';
import { useTable, useSortBy, Column, useExpanded } from 'react-table';
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from 'react-icons/ti';

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: any;
  renderRowSubComponent?: any;
  title?: string;
  defaultSortBy?: { id: string; desc: boolean };
  subComponent?: (row: T) => JSX.Element;
}

const Table = <T extends object>({
  columns,
  data,
  title,
  defaultSortBy,
  subComponent,
}: TableProps<T>) => {
  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          sortBy: defaultSortBy ? [defaultSortBy] : [],
        },
        autoResetSortBy: false,
        autoResetExpanded: false,
      },
      useSortBy,
      useExpanded
    );

  const table = useMemo(
    () => (
      <div className='w-full'>
        <table
          {...getTableProps()}
          className={`table_custom w-full shadow-primary rounded-2xl overflow-hidden ${
            title ? 'rounded-tl-none' : ''
          }`}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`table_custom_header_cell ${
                      (column as any).className ?? ''
                    }`}
                    title=''
                  >
                    {column.render('Header')}
                    {!column.disableSortBy && (
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TiArrowSortedDown className='inline' />
                          ) : (
                            <TiArrowSortedUp className='inline' />
                          )
                        ) : (
                          <TiArrowUnsorted className='inline' />
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <tr className='table_custom_row'>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`table_custom_cell ${
                            (cell.column as any).className ?? ''
                          }`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {subComponent && row.isExpanded && (
                    <tr>
                      <td colSpan={1000} className='m-0 p-0'>
                        {subComponent(row.original)}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    ),
    [
      getTableBodyProps,
      getTableProps,
      headerGroups,
      prepareRow,
      rows,
      subComponent,
      title,
    ]
  );

  const tableLabel = useMemo(() => {
    if (!title) {
      return null;
    }

    return (
      <div className='flex items-center'>
        <div className='px-6 bg-success-1 text-dark rounded-t-md'>{title}</div>
      </div>
    );
  }, [title]);

  return (
    <div className='flex flex-col'>
      {tableLabel}
      {table}
    </div>
  );
};

export default Table;
