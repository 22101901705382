import { observer } from 'mobx-react-lite';
import { useBundlePortfolioStore } from '../providers/BundlePortfolioStoreProvider';
import { useTransactionHistoryStore } from 'providers/transaction-history/TransactionHistoryStoreProvider';
import { useEffect } from 'react';
import TransactionHistory from 'components/transaction-history';

const BundleTransactionHistory = observer(() => {
  const bundlePortfolioStore = useBundlePortfolioStore();
  const transactionHistoryStore = useTransactionHistoryStore();

  useEffect(() => {
    if (!bundlePortfolioStore.bundle) {
      return;
    }

    transactionHistoryStore.init(
      bundlePortfolioStore.bundle.accounts.map((a) => a.address)
    );
  }, [transactionHistoryStore, bundlePortfolioStore.bundle]);

  return (
    <TransactionHistory
      items={transactionHistoryStore.historyItems}
      canLoadMore={transactionHistoryStore.canLoadMore}
      loading={transactionHistoryStore.loading}
      fetchMore={() => transactionHistoryStore.fetchMore()}
      showUserAddress={true}
    />
  );
});

export default BundleTransactionHistory;
