import { ExchangeKeyBalanceDTO } from 'api';
import BigNumber from 'bignumber.js';
import { TokenBalanceModel } from './TokenBalanceModel';

export class ExchangeTokenBalanceModel extends TokenBalanceModel {
  available: BigNumber;
  reserved: BigNumber;

  constructor(dto?: ExchangeKeyBalanceDTO) {
    let available = new BigNumber(dto?.available ?? 0);
    let reserved = new BigNumber(dto?.reserved ?? 0);
    let amount = available.plus(reserved);
    super({
      amount: amount.toString(),
      symbol: dto?.currency,
      rootAddress: null,
      usdPrice: dto?.usdPrice,
      network: dto?.exchange ?? '',
      networkType: 'exchange',
    });
    this.available = available;
    this.reserved = reserved;
    this.isNative = false;
  }

  combine(token: ExchangeTokenBalanceModel): ExchangeTokenBalanceModel {
    if (this.symbol !== token.symbol) {
      throw Error(
        `Cannot combine tokens: symbols mismatch (${this.symbol}-${token.symbol})`
      );
    }

    const result = new ExchangeTokenBalanceModel();

    result.symbol = this.symbol;
    result.usdPrice = this.usdPrice;
    result.amount = this.amount.plus(token.amount);
    result.available = this.available.plus(token.available);
    result.reserved = this.reserved.plus(token.reserved);
    result.total = this.total.plus(token.total);

    return result;
  }
}
