import { Api, ExchangeDTO } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';
import { cutString } from 'utils/strings';

export class ExchangeKeyStore {
  constructor(private api: Api<unknown>) {
    makeAutoObservable(this);
  }

  step: 'init' | 'apiKey' | 'secrets' = 'init';

  exchange?: ExchangeDTO;
  apiKey?: string;
  memo?: string;
  secretKey?: string;
  passphrase: string | null = null;

  isLoading: boolean = false;

  clear() {
    runInAction(() => {
      this.exchange = undefined;
      this.apiKey = undefined;
      this.secretKey = undefined;
      this.passphrase = null;
      this.memo = undefined;
      this.step = 'init';
    });
  }

  backToApiKeyAndMemoStep() {
    if (this.step === 'secrets') {
      runInAction(() => {
        this.step = 'apiKey';
      });
    }
  }

  selectExchange(exchange: ExchangeDTO) {
    runInAction(() => {
      this.exchange = exchange;
      this.step = 'apiKey';
    });
  }

  addApiKeyAndMemo({ apiKey, memo }: { apiKey: string; memo?: string }) {
    if (!this.exchange) {
      return;
    }

    runInAction(() => {
      this.apiKey = apiKey;
      this.memo = memo;
      this.step = 'secrets';
    });
  }

  addSecrets({
    secretKey,
    passphrase,
  }: {
    secretKey: string;
    passphrase: string | null;
  }) {
    if (this.isPassphraseRequired() && !passphrase) {
      return;
    }

    runInAction(() => {
      this.secretKey = secretKey;
      this.passphrase = passphrase ?? null;
    });
  }

  isPassphraseRequired(): boolean {
    return !!this.exchange?.requiresPassphrase;
  }

  async sendExchangeKey() {
    if (
      !this.exchange ||
      !this.apiKey ||
      !this.secretKey ||
      (this.isPassphraseRequired() && !this.passphrase)
    ) {
      return;
    }

    runInAction(() => {
      this.isLoading = true;
    });

    try {
      await this.api.exchange.addExchangeKey({
        exchangeId: this.exchange.id,
        memo:
          this.memo ??
          `${this.exchange.name} ${cutString(this.apiKey, 4, 2, '****')}`,
        apiKey: this.apiKey,
        secretKey: this.secretKey,
        passphrase: this.passphrase,
      });
    } catch (err) {
      throw err;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
