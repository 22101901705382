import './index.css';
import TokenValues from 'components/core/token-values';
import { LockedDepositModel } from 'models/portfolio/LockedDepositModel';
import { bigNumberToStr, unixDateToStr } from 'utils/strings';

const UnlockScheduleTable = ({ data }: { data: LockedDepositModel[] }) => {
  return (
    <table className='w-full'>
      <thead>
        <tr className='table_unlock_header'>
          <th>Unlock</th>
          <th>Votes</th>
          <th>Tokens</th>
          <th>%</th>
          <th>Total USD</th>
        </tr>
      </thead>

      <tbody className='table_unlock_body'>
        {data.map((deposit) => (
          <tr key={deposit.unlockTime}>
            <td>{unixDateToStr(deposit.unlockTime, 'DD.MM.YYYY')}</td>
            <td>
              <TokenValues tokens={[deposit.vePart]} />
            </td>
            <td>
              <TokenValues tokens={[deposit.part]} />
            </td>
            <td>{bigNumberToStr(deposit.percent, 2)}%</td>
            <td className='font-bold'>
              ${bigNumberToStr(deposit.cumulativePart.usdValue, 0)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UnlockScheduleTable;
