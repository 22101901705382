import TransactionHistory from 'components/transaction-history';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTransactionHistoryStore } from 'providers/transaction-history/TransactionHistoryStoreProvider';

const ProfileTransactionHistory = observer(
  ({ address }: { address: string | undefined }) => {
    const transactionHistoryStore = useTransactionHistoryStore();

    useEffect(() => {
      if (!address) {
        return;
      }

      transactionHistoryStore.init([address]);
    }, [address, transactionHistoryStore]);

    return (
      <TransactionHistory
        items={transactionHistoryStore.historyItems}
        canLoadMore={transactionHistoryStore.canLoadMore}
        loading={transactionHistoryStore.loading}
        fetchMore={() => transactionHistoryStore.fetchMore()}
      />
    );
  }
);

export default ProfileTransactionHistory;
