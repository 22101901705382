import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';
import { bigNumberToStr } from 'utils/strings';

interface ProjectTitleProps {
  name: string;
  usdValue?: BigNumber;
  link?: string;
  logo?: string;
}

const ProjectTitle = ({ name, usdValue, link, logo }: ProjectTitleProps) => {
  return (
    <div className='flex items-center justify-between ml-4 text-lg font-semibold'>
      {link ? (
        <Link
          className='flex items-center gap-2 cursor-pointer underline'
          to={link}
        >
          {logo && <img src={logo} className='w-7 h-7' alt='' />}
          <span>{name}</span>
        </Link>
      ) : (
        <div className='flex items-center gap-2'>
          {logo && <img src={logo} className='w-7 h-7' alt='' />}
          <span>{name}</span>
        </div>
      )}
      {usdValue && <span>${bigNumberToStr(usdValue, 0)}</span>}
    </div>
  );
};

export default ProjectTitle;
