import { useMemo } from 'react';
import { unixDateToStr } from 'utils/strings';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  TooltipProps,
  Tooltip,
} from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import BigNumber from 'bignumber.js';

const UsdValueChartTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  return (
    <div className='bg-tooltip-mainBg shadow-primary rounded-2xl text-sm py-2 px-4'>
      {payload.map((item, index) => (
        <div key={index} className='flex flex-col gap-1'>
          <div className='text-secondary-1'>
            {unixDateToStr(item.payload['timestamp'], 'DD.MM.YYYY HH:mm')}
          </div>
          <div className='flex gap-1'>
            <span>Value:</span>
            <span className='font-bold'>
              $
              {Intl.NumberFormat('en-US', {
                notation: 'standard',
                maximumFractionDigits: 2,
              }).format(item.payload['usdValue'])}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

type UsdValueChartProps = {
  data?: { timestamp: number; usdValue: BigNumber }[];
  minHeight: number;
};

const UsdValueChart = ({ data, minHeight }: UsdValueChartProps) => {
  const chartData = useMemo(
    () =>
      data?.map((x) => ({
        timestamp: x.timestamp,
        usdValue: x.usdValue.toNumber(),
      })),
    [data]
  );

  const tooltip = useMemo(() => <UsdValueChartTooltip />, []);

  const chartStyles = useMemo(
    () => ({
      xAxis: {
        height: 45,
        tickMargin: 25,
        tickCount: 10,
      },
      yAxis: {
        width: 60,
        tickCount: 10,
      },
      fontSize: 14,
      lineStroke: 'var(--color-chart-line)',
      lineWidth: 2,
      axisStroke: 'var(--color-chart-axis)',
    }),
    []
  );

  if (!chartData || chartData.length === 0) {
    return (
      <div
        className='flex items-center justify-center text-center text-secondary-1'
        style={{ minHeight: minHeight }}
      >
        <span>No data available</span>
      </div>
    );
  }

  return (
    <ResponsiveContainer minHeight={minHeight}>
      <AreaChart data={chartData} throttleDelay={100}>
        <defs>
          <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
            <stop
              offset='5%'
              stopColor={chartStyles.lineStroke}
              stopOpacity={0.8}
            />
            <stop
              offset='95%'
              stopColor={chartStyles.lineStroke}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Area
          dataKey='usdValue'
          stroke={chartStyles.lineStroke}
          strokeWidth={chartStyles.lineWidth}
          dot={false}
          activeDot={true}
          fillOpacity={1}
          type='monotone'
          fill='url(#colorUv)'
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          axisLine={false}
          tickLine={false}
          domain={[(dataMin: number) => dataMin, (dataMax: number) => dataMax]}
          tickFormatter={(unixTime: number) =>
            unixDateToStr(unixTime, 'DD.MM.YYYY')
          }
          fontSize={chartStyles.fontSize}
          stroke={chartStyles.axisStroke}
          height={chartStyles.xAxis.height}
          tickMargin={chartStyles.xAxis.tickMargin}
          tickCount={chartStyles.xAxis.tickCount}
          interval='preserveStartEnd'
        />
        <YAxis
          type='number'
          dataKey='usdValue'
          orientation='right'
          axisLine={false}
          tickLine={false}
          domain={[0, (dataMax: number) => dataMax]}
          tickFormatter={(value: number) =>
            `$${Intl.NumberFormat('en-US', {
              notation: 'compact',
              maximumFractionDigits: 2,
            }).format(value)}`
          }
          width={chartStyles.yAxis.width}
          fontSize={chartStyles.fontSize}
          stroke={chartStyles.axisStroke}
          tickCount={chartStyles.yAxis.tickCount}
        />
        <Tooltip content={tooltip} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default UsdValueChart;
