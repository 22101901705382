import SnipaRoutes from 'routes';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'providers/AuthProvider';
import { useLocation, Navigate, Routes, Route } from 'react-router-dom';
import ExchangeKeyList from './components/ExchangeKeyList';
import { ProvideExchangeKeyStores } from './providers/ExchangeKeyStoresProvider';

const ExchangeKeyPage = observer(() => {
  const authStore = useAuthStore();
  const location = useLocation();

  if (!authStore.loggedIn) {
    return (
      <Navigate
        to={SnipaRoutes.index.path}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <ProvideExchangeKeyStores>
      <Routes>
        <Route index element={<ExchangeKeyList />} />
        <Route path='*' element={<Navigate to='' />} />
      </Routes>
    </ProvideExchangeKeyStores>
  );
});

export default ExchangeKeyPage;
