import { makeAutoObservable, runInAction } from 'mobx';

const disclaimerStorageKey = 'snipa.testnet_announcement';

export class LayoutStore {
  constructor() {
    makeAutoObservable(this);
  }

  contentModal = {
    show: false,
    content: <></>,
  };

  isMobileSidebarVisible: boolean = false;
  isTestnetDisclaimerVisible: boolean =
    !localStorage.getItem(disclaimerStorageKey);

  showContentModal(content: JSX.Element) {
    runInAction(() => {
      this.contentModal = {
        show: true,
        content: content,
      };
    });
  }

  hideContentModal() {
    runInAction(() => {
      this.contentModal = {
        show: false,
        content: <></>,
      };
    });
  }

  showSidebar() {
    runInAction(() => {
      this.isMobileSidebarVisible = true;
    });
  }

  hideSidebar() {
    runInAction(() => {
      this.isMobileSidebarVisible = false;
    });
  }

  hideTestnetDisclaimer() {
    localStorage.setItem(disclaimerStorageKey, 'hidden');
    runInAction(() => {
      this.isTestnetDisclaimerVisible = false;
    });
  }
}
