import Button from '.';
import { FiRefreshCw } from 'react-icons/fi';

const RefreshButton = ({
  onRefresh,
  isLoading,
}: {
  onRefresh: () => void;
  isLoading: boolean;
}) => {
  return (
    <Button
      variant='info'
      className='!px-4 !py-1 !text-primary-1 !bg-primaryBg-1 !my-1 !text-base'
      onClick={onRefresh}
    >
      <FiRefreshCw className={isLoading ? 'icon-spin' : ''} />
    </Button>
  );
};

export default RefreshButton;
