import { BundleAccountDTO, BundleDTO, BundleExchangeKeyDTO } from 'api';
import { BundleAccountModel } from './BundleAccountModel';
import { BundleExchangeKeyModel } from './BundleExchangeKeyModel';

export class BundleModel {
  id: string;
  name: string;

  accounts: BundleAccountModel[];
  exchangeKeys: BundleExchangeKeyModel[];

  constructor(dto: BundleDTO, accounts: BundleAccountDTO[], exchangeKeys: BundleExchangeKeyDTO[]) {
    this.id = dto.id;
    this.name = dto.name;
    this.accounts = accounts.map((x) => new BundleAccountModel(x));
    this.exchangeKeys = exchangeKeys.map((x) => new BundleExchangeKeyModel(x));
  }
}
