import { useEffect } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { useProfileAnalyticsStore } from 'modules/profile/providers/ProfileAnalyticsStoreProvider';
import Card from 'components/core/card';
import Container from 'components/core/container';
import MultiSelect from 'components/core/select/multi-select';
import UsdValueChart from 'components/core/charts/usd-value-chart';

const Ipl = observer(() => {
  const analyticsStore = useProfileAnalyticsStore();

  if (!analyticsStore.iplData || analyticsStore.iplData.length === 0) {
    return null;
  }

  return (
    <Card className='flex flex-col gap-6 mt-4'>
      <span className='font-bold text-lg'>Impermanent loss</span>
      <UsdValueChart data={analyticsStore.iplData} minHeight={250} />
    </Card>
  );
});

const ProfileAnalytics = observer(
  ({ address }: { address: string | undefined }) => {
    const analyticsStore = useProfileAnalyticsStore();

    useEffect(() => {
      if (!address) {
        return;
      }

      analyticsStore.init(address);
    }, [address, analyticsStore]);

    return (
      <Container className='flex flex-col py-6 lg:py-12'>
        <Card className='flex flex-col gap-6'>
          <div className='flex flex-col gap-4'>
            <span className='font-bold text-lg'>USD Networth</span>
            <Observer>
              {() => (
                <MultiSelect
                  options={analyticsStore.projects}
                  value={analyticsStore.selectedProjects}
                  placeholderText='Select projects...'
                  onChange={(newVal) => analyticsStore.applyFilter(newVal)}
                  isSearchable={false}
                />
              )}
            </Observer>
          </div>

          <Observer>
            {() => <UsdValueChart data={analyticsStore.data} minHeight={250} />}
          </Observer>
        </Card>

        <Ipl />
      </Container>
    );
  }
);

export default ProfileAnalytics;
