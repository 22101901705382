import SnipaRoutes from 'routes';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'providers/AuthProvider';
import { useLocation, Navigate, Routes, Route } from 'react-router-dom';
import Bundle from './components/Bundle';
import BundleNavbar from './components/BundleNavbar';
import NavigateToBundle from './components/router/NavigateToBundle';
import { ProvideBundleStore } from './providers/BundleStoreProvider';

const BundlePage = observer(() => {
  const authStore = useAuthStore();
  const location = useLocation();

  if (!authStore.loggedIn) {
    return (
      <Navigate
        to={SnipaRoutes.index.path}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <ProvideBundleStore>
      <BundleNavbar />

      <Routes>
        <Route path=':bundleId/*' element={<Bundle />} />
        <Route path='*' element={<NavigateToBundle />} />
      </Routes>
    </ProvideBundleStore>
  );
});

export default BundlePage;
