import SnipaRoutes from 'routes';
import Confirmation from 'components/layout/Confirmation';
import Dropdown from 'components/core/dropdown';
import Container from 'components/core/container';
import RefreshButton from 'components/core/button/refresh-button';
import TabsSelect from 'components/core/tabs-select';
import EditBundle from 'modules/bundle/components/modals/EditBundle';
import { observer } from 'mobx-react-lite';
import { useLayoutStore } from 'providers/LayoutStoreProvider';
import { useCallback, useMemo } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { bigNumberToStr } from 'utils/strings';
import { useBundlePortfolioStore } from 'modules/bundle/providers/BundlePortfolioStoreProvider';
import { useBundleStore } from '../providers/BundleStoreProvider';
import { BsPencilFill, BsTrash } from 'react-icons/bs';
import { useSmMediaQuery } from 'utils/responsiveness';
import { useLocation } from 'react-router-dom';
import { useTransactionHistoryStore } from 'providers/transaction-history/TransactionHistoryStoreProvider';
import { showExchangeKeys } from '../../../utils/beta';

const BundleActions = observer(() => {
  const bundleStore = useBundleStore();
  const bundlePortfolioStore = useBundlePortfolioStore();
  const layoutStore = useLayoutStore();

  const onEditBundleClick = useCallback(() => {
    layoutStore.showContentModal(
      <EditBundle
        initValue={bundlePortfolioStore.bundle}
        onCancel={() => layoutStore.hideContentModal()}
        onConfirm={(value) => {
          const editAndHide = async () => {
            await bundleStore.editBundle(value.id, value.name);
            layoutStore.hideContentModal();
          };

          editAndHide();
        }}
      />
    );
  }, [bundlePortfolioStore.bundle, bundleStore, layoutStore]);

  const onRemoveBundleClick = useCallback(() => {
    layoutStore.showContentModal(
      <Confirmation
        header={bundlePortfolioStore.bundle?.name}
        text='Are you sure you want to remove this bundle?'
        onConfirm={() => {
          const deleteAndHide = async () => {
            if (!bundlePortfolioStore.bundle) {
              return;
            }

            await bundleStore.deleteBundle(bundlePortfolioStore.bundle?.id);
            layoutStore.hideContentModal();
          };

          deleteAndHide();
        }}
        onCancel={() => layoutStore.hideContentModal()}
      />
    );
  }, [bundlePortfolioStore.bundle, bundleStore, layoutStore]);

  return (
    <Dropdown
      menuButton={
        <button className='flex items-center'>
          <FaChevronDown className='text-sm' />
        </button>
      }
      actions={[
        { text: 'Edit', onClick: onEditBundleClick, icon: <BsPencilFill /> },
        {
          text: 'Remove',
          onClick: onRemoveBundleClick,
          icon: <BsTrash />,
          className: 'text-danger-1',
        },
      ]}
    />
  );
});

const BundleDetailsNavbar = observer(() => {
  const { pathname } = useLocation();
  const bundlePortfolioStore = useBundlePortfolioStore();
  const transactionHistoryStore = useTransactionHistoryStore();

  const isDesktop = useSmMediaQuery();

  const isLoading = useMemo(
    () => bundlePortfolioStore.loading || transactionHistoryStore.loading,
    [bundlePortfolioStore.loading, transactionHistoryStore.loading]
  );

  const onRefreshClick = useCallback(() => {
    if (!bundlePortfolioStore.bundle || isLoading) {
      return;
    }

    bundlePortfolioStore.fetchPortfolio(bundlePortfolioStore.bundle);

    if (pathname.includes(SnipaRoutes.bundles.history.path)) {
      transactionHistoryStore.refresh();
    }
  }, [bundlePortfolioStore, isLoading, pathname, transactionHistoryStore]);

  const bundleTabs = useMemo(() => {
    let tabs = [
      { id: 'portfolio', text: 'Portfolio', navLink: '' },
      {
        id: 'accounts',
        text: 'Accounts',
        navLink: SnipaRoutes.bundles.accounts.path,
      },
      {
        id: 'history',
        text: 'History',
        navLink: SnipaRoutes.bundles.history.path,
      },
    ];

    if (showExchangeKeys) {
      tabs.push( {
        id: 'exchange-keys',
        text: 'CEX\xa0Keys',
        navLink: SnipaRoutes.bundles.exchangeKeys.path,
      });
    }

    return tabs;
  }, []);

  if (!bundlePortfolioStore.bundle) {
    return null;
  }

  return (
    <div className='bg-navbar-mainBg'>
      <Container className='flex flex-col gap-y-4 sm:gap-y-6'>
        <div className='flex flex-col sm:flex-row justify-between gap-y-2'>
          <div className='flex flex-col'>
            <div className='flex text-2xl font-semibold gap-3'>
              <span>{bundlePortfolioStore.bundle.name}</span>

              <BundleActions />
            </div>
            <div className='flex gap-x-3'>
              <div className='flex items-center gap-1'>
                <span>{bundlePortfolioStore.bundle.accounts.length}</span>
                <span className='text-secondary-1'>Accounts</span>
              </div>
              {showExchangeKeys && bundlePortfolioStore.bundle.exchangeKeys.length > 0 && (
                <div className='flex items-center gap-1'>
                  <span>{bundlePortfolioStore.bundle.exchangeKeys.length}</span>
                  <span className='text-secondary-1'>Exchange Keys</span>
                </div>
              )}
            </div>
          </div>
          <div className='flex justify-between sm:justify-end'>
            <div className='text-2xl font-semibold'>
              ${bigNumberToStr(bundlePortfolioStore.totalUsd, 0)}
            </div>

            {!isDesktop && (
              <RefreshButton onRefresh={onRefreshClick} isLoading={isLoading} />
            )}
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <TabsSelect data={bundleTabs} />

          {isDesktop && (
            <RefreshButton onRefresh={onRefreshClick} isLoading={isLoading} />
          )}
        </div>
      </Container>
    </div>
  );
});

export default BundleDetailsNavbar;
