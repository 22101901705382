import SnipaRoutes from 'routes';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { BundlePortfolioStore } from 'modules/bundle/stores/BundlePortfolioStore';
import { useBundleStore } from './BundleStoreProvider';
import { useApi } from 'providers/ApiProvider';
import { useStaticData } from 'providers/StaticDataStoreProvider';
import { useBundleExchangeKeyStores } from './BundleExchangeKeyStoresProvider';

const bundlePortfolioStoreContext = createContext<BundlePortfolioStore>(
  {} as BundlePortfolioStore
);

export const ProvideBundlePortfolioStore = observer(
  ({ bundleId, children }: { bundleId: string; children: any }) => {
    const api = useApi();
    const staticData = useStaticData();
    const bundlesStore = useBundleStore();
    const { exchangeStore } = useBundleExchangeKeyStores();
    const bundlePortfolioStore = useLocalObservable(
      () => new BundlePortfolioStore(api, staticData, exchangeStore)
    );

    const bundleToSelect = useMemo(
      () => bundlesStore.bundles?.find((x) => x.id === bundleId),
      [bundleId, bundlesStore.bundles]
    );

    useEffect(() => {
      if (!bundleToSelect) {
        return;
      }

      bundlePortfolioStore.fetchPortfolio(bundleToSelect);
    }, [bundlePortfolioStore, bundleToSelect]);

    if (!bundleToSelect) {
      return <Navigate to={`/${SnipaRoutes.bundles.path}`} replace />;
    }

    return (
      <bundlePortfolioStoreContext.Provider value={bundlePortfolioStore}>
        {children}
      </bundlePortfolioStoreContext.Provider>
    );
  }
);

export const useBundlePortfolioStore = () => {
  return useContext(bundlePortfolioStoreContext);
};
