import { useMemo } from 'react';
import { useMdMediaQuery } from 'utils/responsiveness';
import Table from 'components/core/table';
import { bigNumberToStr } from 'utils/strings';
import Tokens from 'components/core/tokens';
import TokenValues from 'components/core/token-values';
import { Column } from 'react-table';
import { PoolModel } from 'models/portfolio/PoolModel';
import { SupplyTokenModel } from 'models/portfolio/SupplyTokenModel';
import LiquidityPoolCards from './LiquidityPoolCards';

const LiquidityPoolTable = ({ poolInfo }: { poolInfo: PoolModel[] }) => {
  const columns = useMemo<Column<PoolModel>[]>(
    () => [
      {
        Header: 'Pool',
        accessor: 'type',
        Cell: ({ row }) => <Tokens tokens={row.original.supplyTokenList} />,
        disableSortBy: true,
      },
      {
        Header: 'Balance',
        accessor: 'supplyTokenList',
        Cell: ({ row }) => (
          <TokenValues
            tokens={row.original.supplyTokenList.map(
              (token: SupplyTokenModel) => ({
                amount: token.amount,
                symbol: token.symbol,
                usdValue: token.usdValue,
              })
            )}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'USD Value',
        accessor: 'totalUsdValue',
        Cell: ({ row }) => (
          <span className='font-bold'>
            ${bigNumberToStr(row.original.totalUsdValue, 0)}
          </span>
        ),
        sortType: (rowA, rowB) => {
          return rowA.original.totalUsdValue.comparedTo(
            rowB.original.totalUsdValue
          );
        },
        className: 'w-48',
      },
    ],
    []
  );

  const defaultSort = useMemo(() => ({ id: 'totalUsdValue', desc: true }), []);
  const isDesktop = useMdMediaQuery();

  return isDesktop ? (
    <Table
      columns={columns}
      data={poolInfo}
      title='Liquidity Pool'
      defaultSortBy={defaultSort}
    />
  ) : (
    <LiquidityPoolCards poolInfo={poolInfo} />
  );
};

export default LiquidityPoolTable;
