import './index.css';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

type InputFieldProps = {
  value?: string | number;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  type?: 'text' | 'password';
  className?: string;
  error?: string;
};

const InputField = (props: InputFieldProps) => {
  const [value, setValue] = useState(props.value);
  const [type, setType] = useState(props.type);

  const handleInput = useCallback(
    (value: string) => {
      setValue(value);
      props.onChange?.(value);
    },
    [props]
  );

  useEffect(() => {
    if (value !== props.value) {
      setValue(props.value);
    }
  }, [props.value, value]);

  return (
    <div className={`w-full ${props.className}`}>
      {props.label && (
        <div className='text-sm text-secondary-1 my-2'>{props.label}</div>
      )}
      <div
        className={
          'flex flex-row items-center gap-2 justify-between ' +
          'w-full px-4 py-2 text-primary-1 input_background ' +
          'border rounded-xl transition-colors duration-200 ' +
          `${
            props.error
              ? 'border-danger-1'
              : 'border-transparent focus-within:border-success-1'
          }`
        }
      >
        <input
          className='bg-transparent w-full'
          value={value}
          placeholder={props.placeholder}
          type={type ?? 'text'}
          onChange={(event) => handleInput(event.target.value)}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          autoComplete='off'
          disabled={props.disabled}
        />

        {props.type === 'password' && (
          <span className='text-lg cursor-pointer text-secondary-1'>
            {type === 'password' && (
              <AiOutlineEye onClick={() => setType('text')} />
            )}

            {type === 'text' && (
              <AiOutlineEyeInvisible onClick={() => setType('password')} />
            )}
          </span>
        )}
      </div>

      {props.error && (
        <span className='flex text-danger-1 text-sm'>{props.error}</span>
      )}
    </div>
  );
};

export default InputField;
