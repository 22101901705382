import './index.css';
import React, { useCallback } from 'react';
import SnipaRoutes from 'routes';
import ThemeSwitcher from './theme-switcher';
import { ReactComponent as SnipaLogo } from 'assets/images/snipa.svg';
import { ReactComponent as SnipaFullLogoDark } from 'assets/images/snipa-full-dark.svg';
import { ReactComponent as SnipaFullLogoLight } from 'assets/images/snipa-full-light.svg';
import { NavLink } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import { VscKey } from 'react-icons/vsc';
import { MdFolderOpen, MdOutlineCases } from 'react-icons/md';
import { useTheme } from 'providers/ThemeProvider';
import { Theme } from 'stores/ThemeStore';
import { observer } from 'mobx-react-lite';
import { BsChevronLeft, BsThreeDots } from 'react-icons/bs';
import { useSmMediaQuery } from 'utils/responsiveness';
import { useLayoutStore } from 'providers/LayoutStoreProvider';
import { loginEnabled, showExchangeKeys } from 'utils/beta';

const SidebarLink = ({
  to,
  text,
  icon,
  onClick,
}: {
  to: string;
  text: string;
  icon: JSX.Element;
  onClick?: () => void;
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex w-full rounded-lg ${
          isActive ? 'text-dark bg-success-1' : 'text-primary-1'
        }`
      }
      onClick={onClick}
    >
      <div className='flex flex-row items-center gap-4 justify-start sm:justify-center xl:justify-start w-full py-1 px-2'>
        <div className='text-2xl'>{icon}</div>
        <div className='sm:hidden xl:block text-sm'>{text}</div>
      </div>
    </NavLink>
  );
};

const Sidebar = observer(() => {
  const theme = useTheme();
  const layoutStore = useLayoutStore();
  const isDesktop = useSmMediaQuery();

  const hideSidebar = useCallback(() => {
    layoutStore.hideSidebar();
  }, [layoutStore]);

  return (
    <React.Fragment>
      {!isDesktop && layoutStore.isMobileSidebarVisible && (
        <div
          className='bg-primaryBg-1 opacity-70 fixed inset-0 z-40'
          onClick={hideSidebar}
        />
      )}

      <div
        className={
          'sidebar_container fixed sm:static flex flex-col ' +
          'top-0 bottom-0 left-0 z-50 transition-transform duration-300 ' +
          (!isDesktop && !layoutStore.isMobileSidebarVisible
            ? '-translate-x-full'
            : '')
        }
      >
        <div className='flex items-center justify-around pt-4 px-3 gap-4'>
          {!isDesktop && (
            <button onClick={hideSidebar}>
              <BsChevronLeft className='text-2xl' />
            </button>
          )}

          <div className='flex items-center justify-center'>
            {theme.current === Theme.Dark && (
              <SnipaFullLogoDark className='sm:hidden xl:block' />
            )}

            {theme.current === Theme.Light && (
              <SnipaFullLogoLight className='sm:hidden xl:block' />
            )}

            <SnipaLogo className='hidden sm:block xl:hidden' />
          </div>
        </div>

        <div className='flex flex-col justify-center items-center my-6 mx-3 gap-2'>
          <SidebarLink
            to={`/${SnipaRoutes.profile.path}`}
            text='Profile'
            icon={<AiOutlineUser />}
            onClick={hideSidebar}
          />

          {loginEnabled && (
            <SidebarLink
              to={`/${SnipaRoutes.bundles.path}`}
              text='Bundles'
              icon={<MdOutlineCases />}
              onClick={hideSidebar}
            />
          )}

          <SidebarLink
            to={`/${SnipaRoutes.projects.path}`}
            text='Projects'
            icon={<MdFolderOpen />}
            onClick={hideSidebar}
          />

          {showExchangeKeys && (
            <SidebarLink
              to={`/${SnipaRoutes.exchangeKeys.path}`}
              text='Exchange Keys'
              icon={<VscKey />}
              onClick={hideSidebar}
            />
          )}

          {!isDesktop && (
            <SidebarLink
              to={`/${SnipaRoutes.mobile.path}`}
              text='More'
              icon={<BsThreeDots />}
              onClick={hideSidebar}
            />
          )}
        </div>

        <div className='mb-6 mt-auto min-w-[6rem] mx-6 sm:mx-2 xl:mx-6'>
          <ThemeSwitcher />
        </div>
      </div>
    </React.Fragment>
  );
});

export default Sidebar;
