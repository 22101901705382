import { PoolType } from 'api';
import { ProjectModel } from 'models/portfolio/ProjectModel';
import { useStaticData } from 'providers/StaticDataStoreProvider';
import { useMemo } from 'react';
import SnipaRoutes from 'routes';
import EscrowingTable from '../escrowing/EscrowingTable';
import FarmingTable from '../farming/FarmingTable';
import LaunchPoolTable from '../launch-pool/LaunchPoolTable';
import LiquidityPoolTable from '../liquidity-pool/LiquidityPoolTable';
import LiquidityTable from '../liquidity/LiquidityTable';
import StakingTable from '../staking/StakingTable';
import VotingTable from '../voting/VotingTable';
import ProjectTitle from './ProjectTitle';

const Project = ({ data }: { data: ProjectModel }) => {
  const staticData = useStaticData();

  const farmingPools = useMemo(
    () =>
      data.pools
        .filter((x) => x.type === PoolType.FarmingPool)
        .flatMap((x) => x)
        .sort((x, y) => -x.totalUsdValue.comparedTo(y.totalUsdValue)),
    [data.pools]
  );

  const gauges = useMemo(
    () =>
      data.pools
        .filter((x) => x.type === PoolType.FarmingPoolV2)
        .flatMap((x) => x)
        .sort((x, y) => -x.totalUsdValue.comparedTo(y.totalUsdValue)),
    [data.pools]
  );

  const liquidityPools = useMemo(
    () =>
      data.pools
        .filter((x) => x.type === PoolType.LiquidityPool)
        .flatMap((x) => x)
        .sort((x, y) => -x.totalUsdValue.comparedTo(y.totalUsdValue)),
    [data.pools]
  );

  const staking = useMemo(
    () =>
      data.pools
        .filter((x) => x.type === PoolType.Staking)
        .flatMap((x) => x)
        .sort((x, y) => -x.totalUsdValue.comparedTo(y.totalUsdValue)),
    [data.pools]
  );

  const liquidity = useMemo(
    () =>
      [...data.liquidity.supplyTokens].sort(
        (x, y) => -x.usdValue.comparedTo(y.usdValue)
      ),
    [data.liquidity.supplyTokens]
  );

  const voting = useMemo(
    () =>
      data.pools
        .filter((x) => x.type === PoolType.Voting)
        .flatMap((x) => x)
        .sort((x, y) => -x.totalUsdValue.comparedTo(y.totalUsdValue)),
    [data.pools]
  );

  const escrowing = useMemo(
    () =>
      data.pools
        .filter((x) => x.type === PoolType.Escrowing)
        .flatMap((x) => x)
        .sort((x, y) => -x.totalUsdValue.comparedTo(y.totalUsdValue)),
    [data.pools]
  );

  const launchPools = useMemo(
    () =>
      [...data.launchPools].sort(
        (x, y) => -x.totalUsdValue.comparedTo(y.totalUsdValue)
      ),
    [data.launchPools]
  );

  return (
    <div id={data.id} className='flex flex-col gap-6'>
      <ProjectTitle
        name={data.name}
        usdValue={data.totalUsdValue}
        logo={staticData.getProjectLogo(data.id)}
        link={`/${SnipaRoutes.projects.path}/${data.id}`}
      />

      {farmingPools && farmingPools.length > 0 && (
        <FarmingTable title='Farming v1' poolInfo={farmingPools} />
      )}

      {gauges && gauges.length > 0 && (
        <FarmingTable title='Farming v2' poolInfo={gauges} />
      )}

      {liquidityPools && liquidityPools.length > 0 && (
        <LiquidityPoolTable poolInfo={liquidityPools} />
      )}

      {liquidity && liquidity.length > 0 && (
        <LiquidityTable tokens={liquidity} />
      )}

      {staking && staking.length > 0 && <StakingTable poolInfo={staking} />}

      {voting && voting.length > 0 && <VotingTable poolInfo={voting} />}

      {escrowing && escrowing.length > 0 && (
        <EscrowingTable poolInfo={escrowing} />
      )}

      {launchPools && launchPools.length > 0 && (
        <LaunchPoolTable poolInfo={launchPools} />
      )}
    </div>
  );
};

export default Project;
