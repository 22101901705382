import { Api, ExchangeDTO } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';

export class ExchangeStore {
  constructor(private api: Api<unknown>) {
    makeAutoObservable(this);
  }

  initialized = false;

  async init() {
    if (this.initialized) {
      return;
    }

    try {
      await this.fetchExchanges();
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.initialized = true;
      });
    }
  }

  private _exchanges: ExchangeDTO[] = [];

  getExchange(exchangeId: string): ExchangeDTO | undefined {
    return this._exchanges.find((x) => x.id === exchangeId);
  }

  get exchanges(): ExchangeDTO[] {
    return this._exchanges;
  }

  private async fetchExchanges() {
    const exchanges = await this.api.exchange.exchangeList();

    runInAction(() => {
      this._exchanges = exchanges.data;
    });
  }
}
