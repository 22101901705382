import SnipaRoutes from 'routes';
import Button from 'components/core/button';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'providers/ThemeProvider';
import { ReactComponent as NotFoundLightSvg } from 'assets/images/404/404-light.svg';
import { ReactComponent as NotFoundDarkSvg } from 'assets/images/404/404-dark.svg';
import { Theme } from 'stores/ThemeStore';
import { Link } from 'react-router-dom';
import Container from 'components/core/container';

type NotFoundRedirectButton = {
  text: string;
  link: string;
};

const NotFound = observer(
  ({
    btn,
    className,
  }: {
    btn?: NotFoundRedirectButton;
    className?: string;
  }) => {
    const theme = useTheme();

    return (
      <Container className={`mb-10 ${className}`}>
        <div className='flex flex-col items-center justify-center'>
          {theme.current === Theme.Dark && (
            <NotFoundDarkSvg className='max-w-[300px] md:max-w-[350px]' />
          )}

          {theme.current === Theme.Light && (
            <NotFoundLightSvg className='max-w-[300px] md:max-w-[350px]' />
          )}

          <Link className='' to={btn?.link ?? SnipaRoutes.index.path}>
            <Button variant='primary'>{btn?.text ?? 'Go to homepage'}</Button>
          </Link>
        </div>
      </Container>
    );
  }
);

export default NotFound;
