import { Ipl } from 'api';
import BigNumber from 'bignumber.js';

export class ImpermanentLossModel {
  constructor(dto?: Ipl) {
    this.timestamp = dto?.timestamp ?? 0;
    this.usdValue = new BigNumber(dto?.ipl ?? 0);
  }

  timestamp: number;
  usdValue: BigNumber;
}
