import Card from 'components/core/card';
import Button from 'components/core/button';
import Confirmation from 'components/layout/Confirmation';
import Container from 'components/core/container';
import AccountsTable from 'modules/bundle/components/tables/AccountsTable';
import EditAccount, {
  OnEditConfirmProps,
} from 'modules/bundle/components/modals/EditAccount';
import { observer } from 'mobx-react-lite';
import { useLayoutStore } from 'providers/LayoutStoreProvider';
import { useCallback } from 'react';
import { cutString } from 'utils/strings';
import { useBundlePortfolioStore } from '../providers/BundlePortfolioStoreProvider';
import { useBundleStore } from '../providers/BundleStoreProvider';

const BundleAccounts = observer(() => {
  const bundleStore = useBundleStore();
  const bundlePortfolioStore = useBundlePortfolioStore();
  const layoutStore = useLayoutStore();

  const editAddressInBundle = useCallback(
    async (props: OnEditConfirmProps) => {
      if (!bundlePortfolioStore.bundle) {
        return;
      }

      if (!props.oldAddress) {
        await bundleStore.addAccountToBundle(
          bundlePortfolioStore.bundle.id,
          props.newAddress,
          props.memo
        );
      } else {
        await bundleStore.editAccountInBundle(
          bundlePortfolioStore.bundle.id,
          props.oldAddress,
          props.newAddress,
          props.memo
        );
      }

      bundlePortfolioStore.fetchPortfolio(bundlePortfolioStore.bundle);
      layoutStore.hideContentModal();
    },
    [bundlePortfolioStore, bundleStore, layoutStore]
  );

  const removeAddressFromBundle = useCallback(
    async (address: string) => {
      if (!bundlePortfolioStore.bundle) {
        return;
      }

      await bundleStore.removeAccountFromBundle(
        bundlePortfolioStore.bundle?.id,
        address
      );

      bundlePortfolioStore.fetchPortfolio(bundlePortfolioStore.bundle);
      layoutStore.hideContentModal();
    },
    [bundlePortfolioStore, bundleStore, layoutStore]
  );

  const onAddAddressClick = useCallback(() => {
    layoutStore.showContentModal(
      <EditAccount
        onConfirm={editAddressInBundle}
        onCancel={() => layoutStore.hideContentModal()}
      />
    );
  }, [editAddressInBundle, layoutStore]);

  const onEditAddressClick = useCallback(
    (address: string, memo?: string) => {
      layoutStore.showContentModal(
        <EditAccount
          initValue={{ address: address, memo: memo }}
          onConfirm={editAddressInBundle}
          onCancel={() => layoutStore.hideContentModal()}
        />
      );
    },
    [editAddressInBundle, layoutStore]
  );

  const onRemoveAddressClick = useCallback(
    (address: string) => {
      layoutStore.showContentModal(
        <Confirmation
          header={cutString(address, 6, 4)}
          text='Are you sure you want to remove this address from bundle?'
          onConfirm={() => removeAddressFromBundle(address)}
          onCancel={() => layoutStore.hideContentModal()}
        />
      );
    },
    [layoutStore, removeAddressFromBundle]
  );

  if (!bundlePortfolioStore.bundle) {
    return null;
  }

  return (
    <Container className='py-6 lg:py-12'>
      {bundlePortfolioStore.bundle.accounts.length === 0 && (
        <Card className='flex flex-col items-center gap-6 py-8'>
          <span className='text-center text-secondary-1'>
            No addresses have been added to this bundle yet
          </span>
          <Button variant='primary' onClick={onAddAddressClick}>
            <span>Add address</span>
          </Button>
        </Card>
      )}

      {bundlePortfolioStore.bundle.accounts.length > 0 && (
        <>
          <div className='flex justify-end mb-5'>
            <Button
              variant='secondary'
              className='!py-0 !text-base'
              onClick={onAddAddressClick}
            >
              Add address
            </Button>
          </div>

          <AccountsTable
            data={bundlePortfolioStore.bundle.accounts}
            onEdit={onEditAddressClick}
            onRemove={onRemoveAddressClick}
          />
        </>
      )}
    </Container>
  );
});

export default BundleAccounts;
