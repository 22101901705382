import { useMemo } from 'react';
import Summary from './summary';
import Project from './project/Project';
import Wallet from './wallet/Wallet';
import { NetworkModel } from 'models/portfolio/NetworkModel';
import { WalletModel } from 'models/portfolio/WalletModel';
import Container from 'components/core/container';

const Portfolio = ({ networks }: { networks?: NetworkModel[] }) => {
  const projectsWithAssets = useMemo(
    () =>
      networks
        ?.flatMap((x) => x.projects)
        ?.filter((project) => project.hasAnyAssets())
        .sort((x, y) => -x.totalUsdValue.comparedTo(y.totalUsdValue)),
    [networks]
  );

  const wallet = useMemo(() => {
    if (!networks) {
      return undefined;
    }

    return networks
      .map((x) => x.wallet)
      .reduce((x, y) => x.combine(y), new WalletModel());
  }, [networks]);

  const networksSorted = useMemo(
    () =>
      networks?.sort((x, y) => -x.totalUsdValue.comparedTo(y.totalUsdValue)),
    [networks]
  );

  return (
    <Container className='py-6 lg:py-12 flex flex-col gap-12'>
      <Summary
        projects={projectsWithAssets}
        wallet={wallet}
        networks={networksSorted}
      />

      <Wallet data={wallet} />

      {projectsWithAssets?.map((project) => (
        <Project key={project.name} data={project} />
      ))}
    </Container>
  );
};

export default Portfolio;
