import { useCallback } from 'react';
import * as yup from 'yup';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/core/input-field';
import Button from 'components/core/button';
import Exchange from '../../Exchange';
import { MdClose } from 'react-icons/md';
import { useExchangeKeyStores } from '../../../providers/ExchangeKeyStoresProvider';
import { useNotification } from 'providers/NotificationProvider';
import { NotificationType } from 'stores/NotificationStore';
import { Observer } from 'mobx-react-lite';

type FormFields = {
  secretKey: string;
  passphrase: string | null;
  requiresPassphrase: boolean;
};

const ExchangeKeyFormSchema = yup.object({
  requiresPassphrase: yup.boolean(),
  secretKey: yup
    .string()
    .max(64, 'Max 64 characters')
    .nullable()
    .required('Field is required'),
  passphrase: yup
    .string()
    .nullable()
    .when('requiresPassphrase', {
      is: true,
      then: yup.string().nullable().required('Field is required'),
    }),
});

const SpecifySecretsStep = () => {
  const { exchangeKeyStore, exchangeKeyListStore } = useExchangeKeyStores();
  const notificationService = useNotification();

  const onCancel = useCallback(
    () => exchangeKeyStore.clear(),
    [exchangeKeyStore]
  );

  const onBack = useCallback(
    () => exchangeKeyStore.backToApiKeyAndMemoStep(),
    [exchangeKeyStore]
  );

  const { handleSubmit, control } = useForm<FormFields>({
    resolver: yupResolver(ExchangeKeyFormSchema),
    mode: 'onChange',
    defaultValues: {
      passphrase: exchangeKeyStore.passphrase,
      secretKey: exchangeKeyStore.secretKey,
      requiresPassphrase: exchangeKeyStore.exchange?.requiresPassphrase,
    },
  });

  const applyForm = useCallback<SubmitHandler<FormFields>>(
    (data) => {
      exchangeKeyStore.addSecrets(data);

      const addExchangeKey = async () => {
        let success = false;
        try {
          await exchangeKeyStore.sendExchangeKey();
          success = true;
        } catch {
          notificationService.addNotification({
            text: 'Invalid exchange key',
            type: NotificationType.Error,
          });
        }

        if (success) {
          await exchangeKeyListStore.fetchExchangeKeys();
          exchangeKeyStore.clear();
        }
      };

      addExchangeKey();
    },
    [exchangeKeyStore, exchangeKeyListStore, notificationService]
  );

  if (!exchangeKeyStore.exchange) {
    return null;
  }

  const description =
    'Enter the secret key' +
    (exchangeKeyStore.exchange.requiresPassphrase ? ' and passphrase' : '');

  return (
    <form
      className='flex flex-col h-full gap-2'
      onSubmit={handleSubmit(applyForm)}
    >
      <div className='flex items-center justify-between'>
        <Exchange exchange={exchangeKeyStore.exchange.id} />
        <MdClose
          className='text-2xl text-secondary-1 cursor-pointer'
          onClick={onCancel}
        />
      </div>
      <div className='text-base text-secondary-1 my-2'>{description}</div>
      <div className='flex flex-col gap-2'>
        <Controller
          control={control}
          name='secretKey'
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <InputField
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              placeholder='Secret key'
              error={error?.message}
              type='password'
            />
          )}
        ></Controller>
        {exchangeKeyStore.exchange.requiresPassphrase && (
          <Controller
            control={control}
            name='passphrase'
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <InputField
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                placeholder='Passphrase'
                error={error?.message}
                type='password'
              />
            )}
          ></Controller>
        )}
      </div>

      <Observer>
        {() => (
          <div className='flex justify-between mt-auto flex-row-reverse'>
            <Button
              variant='primary'
              className='w-28'
              type='submit'
              disabled={exchangeKeyStore.isLoading}
            >
              <span>Save</span>
            </Button>

            <Button
              variant='secondary'
              className='w-28'
              type='button'
              onClick={onBack}
              disabled={exchangeKeyStore.isLoading}
            >
              <span>Back</span>
            </Button>
          </div>
        )}
      </Observer>
    </form>
  );
};

export default SpecifySecretsStep;
