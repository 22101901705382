const SnipaRoutes = {
  index: {
    path: '/',
  },
  profile: {
    path: 'profile',
    history: {
      path: 'history',
    },
    analytics: {
      path: 'analytics',
    },
  },
  bundles: {
    path: 'bundles',
    history: {
      path: 'history',
    },
    accounts: {
      path: 'accounts',
    },
    exchangeKeys: {
      path: 'exchange-keys',
    },
  },
  projects: {
    path: 'projects',
    details: {
      analytics: {
        path: 'analytics',
      },
    },
  },
  exchangeKeys: {
    path: 'exchange-keys',
  },
  mobile: {
    path: 'more',
  },
} as const;

export default SnipaRoutes;
