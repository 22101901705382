import { Api } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';
import { StaticDataStore } from 'stores/StaticDataStore';

const defaultDebounceMs = 250;

export type SearchFieldValue = {
  label: string;
  value: string;
  logoUrl?: string | undefined;
  type: 'Address' | 'Project';
};

export class SearchFieldStore {
  constructor(private api: Api<unknown>, private staticData: StaticDataStore) {
    makeAutoObservable(this);
  }

  private searchTimeoutHandler: NodeJS.Timeout | undefined;

  search(value: string, callback: (data: SearchFieldValue[]) => void) {
    if (this.searchTimeoutHandler) {
      clearTimeout(this.searchTimeoutHandler);
    }

    runInAction(() => {
      this.searchTimeoutHandler = setTimeout(() => {
        this.performSearch(value, callback);
      }, defaultDebounceMs);
    });
  }

  private async performSearch(
    value: string,
    callback: (data: SearchFieldValue[]) => void
  ) {
    try {
      const projects = await this.fetchProjects(value);
      const addresses = await this.fetchAddresses(value);

      callback(projects.concat(addresses));
    } catch {
      callback([]);
    }
  }

  private async fetchAddresses(value: string) {
    const result = await this.api.autocomplete.search({ query: value });

    const data: SearchFieldValue[] = result.data.map((x) => ({
      label: x.account,
      value: x.account,
      type: 'Address',
    }));

    return data;
  }

  private async fetchProjects(value: string) {
    const valueLowercase = value.toLowerCase();

    const projects: SearchFieldValue[] = this.staticData.allProjects
      .filter(
        (x) =>
          x.id.includes(valueLowercase) ||
          x.name.toLowerCase().includes(valueLowercase)
      )
      .map((x) => ({
        label: x.name,
        value: x.id,
        logoUrl: this.staticData.getProjectLogo(x.id),
        type: 'Project',
      }));

    return projects;
  }
}
